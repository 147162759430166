<template>
  
   <div class="main">
     <el-scrollbar height="700px">
       <el-table :data="tableData" style="width: 100%" height="700">
         <el-table-column prop="id" label="ID" width="180" header-align="center"  align="center"/>
         <el-table-column prop="type" label="操作类型" width="180"  header-align="center" align="center"/>
         <el-table-column prop="operation" label="操作内容" width="180"  header-align="center" align="center" />
         <el-table-column prop="ip" label="操作人IP" width="180" header-align="center" align="center"/>
         <el-table-column prop="user" label="操作人" width="180"  header-align="center" align="center" />
         <el-table-column prop="time" label="操作时间" width="180" header-align="center" align="center"/>
         <el-table-column>
           <template #header>
             <div style="display: flex;">
               <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                 <Search />
               </el-icon>
               <input type="text"
                 style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;" v-model="seaid">
             </div>
           </template>
           <template #default="scope">
             <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
               plain v-on:click="centerDialogVisible = true">
               删除
             </el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-scrollbar>
  
       <div class="deleteDialog">
         <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
           <span>
             是否确定删除此条数据
           </span>
           <template #footer>
             <div class="dialog-footer">
               <el-button @click="centerDialogVisible = false">否</el-button>
               <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                 是
               </el-button>
             </div>
           </template>
         </el-dialog>
       </div>
     </div>
     <div>
       <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
         :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
         :total="total">
       </el-pagination>
     </div>
  
  
  </template>
  <script setup>
  import {
   Search, Plus, Refresh
  } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  const centerDialogVisible = ref(false)
  </script>
  
  <script>
  import { reactive, ref } from 'vue'
  import axios from 'axios'
  export default {
  
   data() {
     return {
       tableData: [],
       currentPage: 1,  //当前页
       pageSize: 10, //页面数据条数
       total: '100',
       showEventInfo1:'',
       seaid:'',
       deleteId:'',
     }
   },
  
   methods: {
  
     //换条数
     handleSizeChange(val) {
       this.pageSize = val;
       this.info()
     },
     //换页数
     handleCurrentChange(val) {
       this.currentPage = val;
       this.info()
     },
     //获取用户信息
     info() {
       axios.get(`/api/logs/searchall?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
         console.log(rel)
         this.tableData = rel.data.data.records
         this.currentPage = rel.data.data.current
         this.pageSize = rel.data.data.size
         this.total = rel.data.data.total
       })
     },
     //上传图片完成回调
    //  handleAvatarSuccess(res, file) {
    //    this.sqlimg = res.data;
    //    console.log(this.sqlimg)
    //    this.imageUrl = URL.createObjectURL(file.raw);
    //    console.log(this.imageUrl)
    //  },
    //  //上传图片过滤
    //  beforeAvatarUpload(file) {
    //    const isJPG = file.type === 'image/jpeg';
    //    const isLt2M = file.size / 1024 / 1024 < 2;
  
    //    if (!isJPG) {
    //      this.$message.error('上传头像图片只能是 JPG 格式!');
    //    }
    //    if (!isLt2M) {
    //      this.$message.error('上传头像图片大小不能超过 2MB!');
    //    }
    //    return isJPG && isLt2M;
    //  },
     //用户信息回显
     handleEdit(number, User) {
       console.log(number, User)
       this.name = User.name
       this.userid = User.userid
       this.state = User.state
       this.userphone = User.userphone
       this.post = User.post
      //  this.imageUrl = `/api/common/download?name=${User.img}`
     },
     //查询方法
     Searchqinxun() {
       const id =this.seaid
       axios.get(`/api/user/seleuserid?id=${id}`).then((rel) => {
         console.log(rel);
         this.tableData = rel.data.data  
       })
     },
     // 删除方法
     deleteat() {
        
       const id = this.deleteId
       axios.delete(`/api/logs/deletelogs?id=${id}`).then((rel) => {
         console.log(rel);
         ElMessage({
           message: '删除成功',
           type: 'success',
         })
         this.info()
       })
     },
     //上传文件提交选定表
     submitUpload() {
       this.$refs.uploadRef.submit();
     },
     //删除按钮行数选定
     handleDelete(index, User) {
       console.log(index, User)
       this.deleteId = User.id
  
     },
  
     // upimg(){
     //   axios.post('/api/common/upload',{
  
     //   }).then((rel)=>{
     //     console.log(rel)
     //   })
     // },
     //编辑上传图片变换
     handleFileChange(file, fileList) {
       this.imageUrl = URL.createObjectURL(file.raw);
       if (fileList.length > 0) {
         this.fileList = [fileList[fileList.length - 1]]
       }
  
       // 当文件状态发生变化时触发，此处用于更新预览图片  
     },
     //身份认证
     userpost() {
       const posts = localStorage.getItem("posts")
       console.log("身份验证",posts)
       if (posts == "后端管理员" || posts == "教练负责人") {
         console.log("身份验证成功")
         this.showEventInfo1 = true;
       } else {
         console.log("身份验证结束")
         this.showEventInfo1 = false;
       }
     },
     //筛选
     condition(s) {
       axios.post('/api/user/condition', {
         name:this.inputname,
         ctype:s,
       }).then((rel)=>{
         console.log(rel)
         this.tableData = rel.data.data  
       })
     },
     ToAdd(){
      this.$router.push({ name: 'add' });
     }
    //  getImage(image) {//图片遍历
    //    return `/api/common/download?name=${image}`
    //  },
   },
  
   created() {
     this.info();
     this.userpost()
   },
  
  
  
  }
  </script>
  
  <style>
  .head {
   border: 1px solid #000;
   width: 100%;
   height: 50px;
  }
  
  .avatar-uploader .el-upload {
   border: 1px dashed #d9d9d9;
   border-radius: 6px;
   cursor: pointer;
   position: relative;
   overflow: hidden;
  }
  
  .avatar-uploader .el-upload:hover {
   border-color: #409EFF;
  }
  
  .avatar-uploader-icon {
   font-size: 28px;
   color: #8c939d;
   width: 178px;
   height: 178px;
   line-height: 178px;
   text-align: center;
  }
  
  .avatar {
   width: 178px;
   height: 178px;
   display: block;
  }
  
  .for {
   display: flex;
   flex-direction: column;
   align-items: center;
   /* 垂直居中 */
   /* 注意：这种方式会影响所有表单项，包括它们的标签 */
  }
  </style>