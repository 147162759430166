<template>
  <div ref="chartDom" id="chartDomid"  style="width: 854px; height: 247px;  background-color: #fff; border-radius: 5px;"></div>
</template>

<script>
import * as echarts from 'echarts';
import { markRaw } from 'vue';
export default {
  props: ['message'],
  data() {
    return {
      myChart: null,
       option :{
        // 这里是你的ECharts配置项  
        title: {
          text: '近五天'
        },
        tooltip: {
          trigger: 'axis'
        },  
        legend: {
          // data: ['三分', '两分', '罚篮', '上篮', '耐力爆发']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1', '2', '3', '4', '5',]
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '三分',
            type: 'line',
            stack: 'Total',
            data: this.message.three
          },
          {
            name: '两分',
            type: 'line',
            stack: 'Total',
            data: this.message.TOWS
          },
          {
            name: '上篮',
            type: 'line',
            stack: 'Total',
            data: this.message.lay
          },
          {
            name: '罚球',
            type: 'line',
            stack: 'Total',
            data:  this.message.freeth
          },
          {
            name: '耐力爆发',
            type: 'line',
            stack: 'Total',
            data:  this.message.SHU
          }
        ]
      }
    }
  },
  watch: {
            // 观察message的变化，并据此更新图表  
            message: {
                handler(newVal) {
                    // 当chartData变化时，更新currentChartData并重新渲染图表  
                    this.option.series[0].data= newVal.three; // 假设newVal是完整的图表配置或数据  
                    this.option.series[1].data= newVal.TOWS;// 假设newVal是完整的图表配置或数据  
                    this.option.series[2].data= newVal.lay; 
                    this.option.series[3].data= newVal.freeth;
                    this.option.series[4].data= newVal.SHU;
                    this.myChart.setOption(this.option);
                },
                deep: true // 开启深度监听  
            }
        },
  
  methods: {
    initChart() {
      const chartDom = this.$refs.chartDom;
      this.myChart =markRaw(echarts.init(chartDom));
      this.myChart.setOption(this.option);
    }
  },

  mounted() {
    this.initChart();
  },

  beforeUnmount() {
    if (this.myChart) {
      this.myChart.dispose();
    }
  },
}
</script>

<style sc></style>