<template>
  <div class="demo-collapse" style="width: 303;">
    <el-scrollbar height="300px">
      <el-collapse v-model="activeNames" @change="handleChange" style="width: 309px; height: 372px;">
        <el-collapse-item v-for="(item, index) in items" :key="index" :title="item.noticehead" :name="`item-${index}`">
          <!-- <div v-for="(content, idx) in item.content" :key="`content-${idx}`">
            {{ content }}
          </div> -->
          <div class="noticontent">
            {{ item.noticontent}}
          </div>
          <p style="margin-left: 150px;">发布人：{{item.noticeadmin}}</p>
        </el-collapse-item>
      </el-collapse>
    </el-scrollbar>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      activeNames: ['1'],
      items:[]  
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    homenotice(){
      axios.get('/api/notice/homenotice').then((rel)=>{
        this.items=rel.data.data
        console.log(this.items)
      })
    }
  },
  created() {
   this.homenotice()
  },
}
</script>