<!-- 用户界面 -->
<template>
    <div class="main">
        <div class="head" >
            <div class="logo" style="width: 18%; height: 100%; margin-right: 60%; display: flex;">
                <img  style="width: 100%; height: 100%; " src="../../assets/userlogo.jpg" alt="logo">
                <img  style="width: 100%; height: 100%; " src="../../assets/logoname.jpg" alt="logo">
            </div>
            <div class="menu"  >
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
                    @select="handleSelect" active-text-color="#000000"  router="true"  style="  height: 100%; width: 80%; border: none; background-color: #fff;">
                    <el-menu-item index="/userHome" ><h1>首页</h1></el-menu-item>
                    <el-sub-menu index="2">
                        <template #title><h1>俱乐部</h1></template>
                        <el-menu-item index="/userHome/usertow"><h2>介绍</h2></el-menu-item>
                        <!-- <el-menu-item index="/userHome/userThree"><h2>荣誉</h2></el-menu-item>
                        <el-menu-item index="/userHome/userfour"><h2>吉祥物</h2></el-menu-item> -->
                    </el-sub-menu>
                    <el-menu-item index="/userHome/userCompetition"><h1>赛事</h1></el-menu-item>
                    <el-menu-item index="/"><h1>登录</h1></el-menu-item>
                </el-menu>
            </div>
        </div>
        <div class="content">
        
            <router-view></router-view>
        <!-- 表示当前页面的子路由会在<router-view />里面展示      -->
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        
    }
  },
}
</script>

<style scoped>
.main {
    height: 100vh;
    width: 100vw;
}

.head {
    height: 11%;
    width: 100%;
    display: flex;
    border: none;
    background: #fff;
}



.content {

    height: 89%;
    width: 100%;
    overflow: hidden;

}
</style>