<template>
    <div style="background: #102749; width: 100%; height: 15%; margin-bottom: 2%; ">
    
    <span style=" margin: 50%;"><h1 style="color: aliceblue; ">介绍</h1></span> 
 
 </div>
  <div class="jiesao">
    <el-scrollbar height="800px">
    <div class="imglogo"> 
      <img src="../../assets/jiesao.jpg" alt="" style="width: 100%; height: 100%;">
    </div>
    <div class="jianjie" style="display: flex; margin-top: 30px;">
      <div class="chengyuan" >
        <dl>
          <dt></dt>
          <dt ><h2  style="font-weight: bold; text-align: left; margin-top: 50px; margin-left: 50px;">俱乐部领导成员</h2></dt>
          <dd><h3  style="font-weight:bold;text-align: left; margin-top: 10px; margin-left: 100px;">董事长-欧旭焱</h3></dd>
          <dd><h3  style="font-weight:bold ;text-align: left;margin-top: 10px; margin-left: 100px;">董事-陈志斌</h3></dd>
          <dt><h2  style="font-weight: bold; text-align: left; margin-top: 50px; margin-left: 50px;">福绵青训男篮</h2></dt>
          <dd><h3  style="font-weight:bold ;text-align: left;margin-top: 10px; margin-left: 100px; margin-top: 30px;">青训队主教练：西热力江</h3></dd>
          <dd><h3  style="font-weight:bold ;text-align: left;margin-top: 10px; margin-left: 100px; margin-top: 30px;">青训队助理教练员：马壮</h3></dd>
          <dd><h3  style="font-weight:bold ;text-align: left;margin-top: 10px; margin-left: 100px; margin-top: 30px;">青训体能教练：Michael</h3></dd>
        </dl>
        <dd>
          <h3  style="font-weight:bold ;text-align: left;margin-top: 10px; margin-left: 100px; margin-top: 30px;">青训队运动员：西热力江、曾繁日、王子瑞、丁彦雨航、林葳、赵柏清、王岚嵚、焦恩格尔·胡依山、李泓澎、王睿、魏一、尹椿文、邬挺嘉、刘东、王彦凯、张富垚、马国栋、李云开、麦吾兰。
          </h3></dd>
       
      </div>
      <div class="ji" style="width: 53%; height: 800px;">
        <p><h3>福绵青训俱乐部于2007年9月在广西玉林正式成立，2007年底正式组队，开始征战全国男子篮球联赛（NBL联赛）。七年NBL征程，球队从未缺席NBL四强，更是四进NBL总决赛，并两夺NBL总冠军。2010年，在全体教练员、运动员的顽强拼搏下，南京同曦队获得了全国男子篮球联赛（NBL）总冠军的好成绩，这是广西职业篮球联赛史上的第一个总冠军，南京同曦队也因此走上了一个新的台阶；2011年，福绵青训俱乐部更是加大投资力度，壮大队伍，奋起拼搏，在2011年NBL联赛中再次取得了辉煌，成功卫冕冠军，成为NBL历史上第一个两连冠队伍。</h3></p>
        <p><h3>2014年，球队虽然没能最终问鼎NBL总冠军，但是在没有外援的艰难条件下，半决赛依靠全华班的顽强拼搏，逆转拥有NBA级别外援的强大对手杀进总决赛，充分展现了“不怕困难、拼搏到底”的同曦精神，书写了没有“外援力量”而实现“中国梦”的生动案例。2014年9月3日，福绵青训俱乐部进军CBA获联赛委员会全票通过，成功晋级中国篮球顶级联赛。在CBA联赛中，福绵青训男篮已经奋勇拼搏了四个赛季，力争在体育领域成为广西的一张城市名片。</h3></p>
        
        <p><h3>2017年，福绵青训篮球俱乐部在新三板成功上市，并大力发展体育产业，与南京师范大学合作成立体育产学研研究基地，广西篮球训练营、广西舞蹈工作室、同曦A·kids少儿运动成长中心不断发展壮大，为福绵青训篮球俱乐部的未来发展添砖加瓦。</h3></p>
        <p><h3>福绵青训篮球俱乐部为广西职业篮球的发展贡献了积极力量，也为同曦集团打造篮坛精品，构建同曦和谐的、高品位的文化体育生活发挥了积极作用。接下来，俱乐部将以打造“规范高效的俱乐部”为目标，进一步规范管理，健全内部体系，明确分工，目标长远，着力加强队伍梯队建设，完善同曦青训体系，培养出更多的优秀篮球人才，实现血液自造，为中国篮球做出积极贡献。</h3></p>
      </div>
    </div>
  </el-scrollbar>
  </div>

</template>


<script>
export default {

}
</script>

<style scoped>
.jiesao{
  width: 100%;
  height: 100vh;
  
}
.imglogo{
  margin-top: 100px;
  margin-left: 190px;
  width: 1489px;
  height: 900px;

}
.chengyuan{
  width: 25%;
  height: 800px;
  margin-left: 190px;
  margin-right: 20px;
  background-color: #F0F2F5;
}
p{
  text-indent:2em;
  font-size:20px;
  word-spacing: 10px;
  line-height:1.5;
}
</style>