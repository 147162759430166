import { createStore } from 'vuex'
import axios from 'axios';
export default createStore({
  state: {
   
  },
  getters: {
  },
  mutations: {//更新用户信息
   
  },
  actions: {
    // async fetchUserInfo({ commit }) {  
    //   try {  
    //     // 使用axios发起GET请求来获取用户信息  
    //     // 注意：这里的URL 'https://api.example.com/user' 只是一个示例，你需要替换成实际的API URL  
    //     // const response = await axios.get('/api/userinfotow');  
          
    //     // 假设API返回了一个包含用户信息的对象  
    //     // 并且这个对象可以直接被用于更新state  
    //     const userInfo = response.data.data; // 从响应数据中获取用户信息  
    //     console.log("vuex",userInfo)
    //     // 调用mutation来更新state  
    //     commit('SET_USER_INFO', userInfo);  
    //   } catch (error) {  
    //     // 处理请求中的错误  
    //     console.error('Error fetching user info:', error);  
    //     // 在这里，你也可以根据需要抛出一个错误，或者在Vuex中处理错误状态  
    //     // 例如，你可以提交一个mutation来设置一个错误状态  
    //     // commit('SET_ERROR', error.message);  
    //   }  
    // }, 
  },
  modules: {
  }
})
