<template>
    <div ref="chartDom" style="width: 359px;height:330px; background: #fff;"></div>
</template>

<script>
import * as echarts from 'echarts';
import { markRaw } from 'vue';
import { message } from 'ant-design-vue';
export default {
    name: 'RadarChart',
    props: ['message'],
    data() {
        return {
            chart: null,
            option: {
                title: {
                    text: '能力分析图'

                },
                legend: {
                    right: '20%',
                    data: ['个人能力']
                },
                radar: {
                    radius: 95,
                    indicator: [
                        { name: '三分', max: 100 },
                        { name: '两分', max: 100},
                        { name: '篮下', max: 100 },
                        { name: '罚篮', max: 100 },
                        { name: '动态天赋', max: 10 },
                        { name: '耐力爆发', max: 10 },
                        { name: '上肢力量', max: 10 },
                        { name: '下肢力量', max: 10 },
                        { name: '核心力量', max: 10 },
                    ]
                },
                series: [
                    {
                        name: '个人能力分析',
                        type: 'radar',
                        data: [
                            {
                                value:this.message,
                                name: '个人能力'
                            },
                        ]
                    }
                ]
            }
        };
    },
    watch: {
            // 观察message的变化，并据此更新图表  
            message: {
                handler(newVal) {
                    console.log("父传子",this.message)
                    console.log("前一个数",newVal)
                    // 当chartData变化时，更新currentChartData并重新渲染图表  
                    this.option.series[0].data[0] = {
                        value: newVal
                    }; // 假设newVal是完整的图表配置或数据  
                    this.chart.setOption(this.option);
                },
                deep: true // 开启深度监听  
            }
        },
    mounted() {
        this.initChart();
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    methods: {
        initChart() {
            // 获取DOM元素  
            const chartDom = this.$refs.chartDom;
            // 初始化ECharts实例  
            this.chart = markRaw(echarts.init(chartDom));
            // 设置ECharts配置项  

            // 使用配置项和数据显示图表  
            this.chart.setOption(this.option);
        }
    }
};
</script>

<style scoped>
/* 你可以在这里添加一些CSS样式 */
</style>