<template>
    <div class="amian">
        <div class="box">
            <div>
                <!-- <img src="../assets/logo.png" alt="logo"> -->
                <h2>福绵区青训俱乐部</h2>
            </div>

            <div class="inputbox">
                <input type="text" name="username" v-model="username" required>
                <label>账号</label>
            </div>
            <div class="inputbox">
                <input type="password" name="password" v-model="password" required>
                <label>密码</label>
            </div>
            <div class="inputbox">
                <input type="text" name="code" v-model="code" required>
                <label>验证码</label>
                <img src="/api/login/img" alt="验证码" @click="lgcode()" ref="vcImg" style="width: 250px; height: 50px;">
            </div>
            <input type="checkbox" name="question1" value="合约">
            <span style="color: aliceblue;">我已阅读</span>
            <a href="wwww.baidu.com" style="text-decoration:none; color: red;">《福绵青训系统用户协议》</a>
            <span style="color: aliceblue;">及</span> <a href="wwww.baidu.com"
                style="text-decoration:none; color: red;">《隐私条款》</a>

            <p>

                <el-radio-group v-model="radio">
                    <el-radio :value="0" style="color: #fff; size: small;">管理员</el-radio>
                    <el-radio :value="6" style="color: #fff;">教练</el-radio>
                    <el-radio :value="7" style="color: #fff;">用户</el-radio>
                    <el-radio :value="8" style="color: #fff;">运动员</el-radio>
                </el-radio-group>

            </p>

            <p>
                <button type="submit" v-on:click="login()" style="width: 150px; height: 40px;">登录</button> <button
                    v-on:click="zuce" style="width: 150px; height: 40px;">注册 </button>

            </p>

            <div >
                <ball v-if="balls"></ball>
            </div>
        </div>

    </div>
</template>
<script setup>
import { ElMessage } from 'element-plus'
</script>

<script>
// import axiosInstance from './Api/login'
import { ref } from 'vue'
import axios from 'axios'
import ball from '../components/ball.vue'
export default {
    data() {
        return {

            username: 'admin',
            password: '',
            code: '',
            tokenTime: '',
            tokenValue: '',
            radio: ref(7),
            balls:false,
        };
    },
    components: {
        ball,
    },
    methods: {
        lgcode() {
            this.$refs.vcImg.src = '/api/login/img?' + Math.random()
        },
        login() {
            this.balls=true
            // 在这里执行登录逻辑  
            axios.post('api/login', {
                username: this.username,
                password: this.password,
                code: this.code,
                radio: this.radio
            }).then((rel) => {
                this.balls=false
                console.log(rel);
                if (rel.data.code == 200) {

                    ElMessage({
                        message: '登录成功',
                        type: 'success',
                    })
                    const tokenValue = rel.data.data.data.tokenValue;
                    const tokenTime = rel.data.data.data.tokenTimeout;
                    console.log(tokenValue);
                    localStorage.setItem('tokenValue', tokenValue);
                    localStorage.setItem('tokenTime', tokenTime);

                    window.location.href = '/Home'
                }
                else {
                    alert(rel.data.message)
                }
            })

            // 发送请求到服务器...  
        }
    }
};  
</script>
<style>
.amian {

    width: 100%;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    font-family: sans-serif;
    background-image: url(../assets/bg2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*实现块元素百分比下居中*/
    width: 450px;
    padding: 50px;
    background: rgba(0, 0, 0, .8);
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, .5);
    border-radius: 15px;
}

.box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
}

.box .inputbox {
    position: relative;
}

.box .inputbox input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.box .inputbox label {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.box .inputbox input:focus~label,
.box .inputbox input:valid~label {
    top: -18px;
    left: 0;
    color: #03a9f4;
    font-size: 14px;
}

.box input[type="submit"] {
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
    background: #03a9f4;
    padding: 15px 20px;
    cursor: pointer;
    border-radius: 10px;
}
</style>