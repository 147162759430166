<template>
    <el-container>
        <div style="height: 100%; width:200px ; background: #000;">
            <Aside :asideWidth="asideWidth"  />
        </div>
      
      <div style="width: 100%;flex-wrap: wrap;">
      <Header :collapseIcon="collapseIcon" :handleCollapse="handleCollapse" />
        <el-main class="mains">
          <router-view></router-view>  <!-- 表示当前页面的子路由会在<router-view />里面展示      -->
        </el-main>
      </div>
    </el-container>
</template>

<script>

import Aside from '../components/Aside.vue';
import Header from '../components/Header.vue';
 
export default {
  components: {
    Aside,
    Header,
  },
  data() {
    return {
      asideWidth: '200px',   //侧边栏默认值为200
    };
  },
  methods: {
 
}
 
};
</script>

<style scoped>

</style>