<template>
    <el-scrollbar height="100%" w >
        <el-table ref="singleTable" :data="datatable" highlight-current-row @current-change="handleCurrentChange"
            style="width: 100%; border-radius: 5px;">
            <el-table-column prop="athletesid" label="球员ID" width="50" header-align="center" align="center" />
            <el-table-column prop="shots" label="投篮总数" width="50" header-align="center" align="center" />
            <el-table-column prop="towshots" label="两分投篮总数" width="50" header-align="center" align="center" />
            <el-table-column prop="towhit" label="两分投中数" width="50" />
            <el-table-column prop="threeshots" label="三分投篮总数" width="50" header-align="center" align="center" />
            <el-table-column prop="threehit" label="三分投中数" width="50" header-align="center" align="center" />
            <el-table-column prop="layup" label="上篮总数" width="50" header-align="center" align="center" />
            <el-table-column prop="layuphit" label="上篮中球数" width="50" header-align="center" align="center" />
            <el-table-column prop="freethrow" label="罚篮总次数" width="50" header-align="center" align="center" />
            <el-table-column prop="freethrowhit" label="罚篮中次数" width="50" header-align="center" align="center" />
            <el-table-column prop="touchheight" label="原地摸高" width="50" header-align="center" align="center" />
            <el-table-column prop="vitalcapacity" label="肺活量" width="50" header-align="center" align="center" />
            <el-table-column prop="shuttlerun" label="折返跑" width="50" header-align="center" align="center" />
            <el-table-column prop="weightsquat" label="深蹲负重" width="50" header-align="center" align="center" />
            <el-table-column prop="slidestep" label="滑步训练" width="50" header-align="center" align="center" />
            <el-table-column prop="bench" label="负重卧推" width="50" header-align="center" align="center" />
            <el-table-column prop="waistline" label="腰围" width="50" header-align="center" align="center" />
        </el-table>
    </el-scrollbar>
    <!-- <div style="margin-top: 20px">
        <el-button @click="setCurrent(tableData[1])">选中第二行</el-button>
        <el-button @click="setCurrent()">取消选择</el-button>
    </div> -->
</template>

<script>
import axios from 'axios';
export default {
    props: ['datatable'],
    data() {
        return {
            tableData: [],
            currentRow: null,
          
        }
    },

    methods: {
        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        }


    }
}
</script>

<style></style>