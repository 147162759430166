<template>
  <div style="width:200px ;height: 100vh; background: #000; max-height: 1000px;">
    <el-aside width="200px" style="background: #000; height: 100%; ">
      <div
        style="height: 60px; background: #000; line-height: 60px; font-size: 20px; display: flex; align-items: center; justify-content: center">
        <img :src="require('@/assets/logo.png')" style="width: 60px;" alt="">
        <span class="logo-title" style="color: #fff;">福绵体育</span>
      </div>
      <el-menu style="border: none;" default-active="2" class="el-menu-vertical-demo" @open="handleOpen"
        @close="handleClose" background-color="#000" text-color="#bfcbd9" active-text-color="#f4f4f5" router>
        <el-menu-item index="/Home">
          <el-icon><House /></el-icon>
          <span>首页</span>
        </el-menu-item>
        <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <Basketball />
            </el-icon>
            <span>俱乐部</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="showEventInfo1" index="/RegionalClub" >地方俱乐部管理</el-menu-item>
            <el-menu-item v-if="showEventInfo2"  index="/YouthIevel">青训球员人才库</el-menu-item>
          </el-menu-item-group>

        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>
            <el-icon><List /></el-icon>
            <span>科学训练</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="showEventInfo3" index="/trainingData">训练数据</el-menu-item>
            <el-menu-item v-if="showEventInfo6" index="/analyse">训练数据分析</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu index="4">
          <template #title>
            <el-icon><Avatar /></el-icon>
            <span>人员管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item  v-if="showEventInfo4" index="/coach">教练管理</el-menu-item>
            <el-menu-item  v-if="showEventInfo5" index="/user">用户管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="5">
          <template #title>
            <el-icon><InfoFilled /></el-icon>
            <span>赛事管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item  index="/EventManagement">赛事信息</el-menu-item>
            <el-menu-item  index="/field">报名信息</el-menu-item>
            <el-menu-item   index="/inform">通知公告</el-menu-item>
            <el-menu-item   index="/logs">操作日志</el-menu-item>
          </el-menu-item-group>


        </el-sub-menu>
      </el-menu>
    </el-aside>


  </div>

</template>

<script setup>
import {
  House,
  Basketball,
  List,
  Avatar,
  InfoFilled,
} from '@element-plus/icons-vue'
</script>
<script>
import axios from 'axios';
export default {
  data() {  
  return {  
    userpost:"",
    userid:"",
    showEventInfo1:true ,
    showEventInfo2:true ,
    showEventInfo3:true ,
    showEventInfo4:true ,
    showEventInfo5:true ,
  }; 
},
methods:{
   userinfow(){
     axios.get('/api/userinfotow').then((rel)=>{
      this.userpost=rel.data.data['post']
                this.userid=rel.data.data['userid']
                this.Role()
     })
   },

   Role(){
    const prefix = this.userid.toString().slice(0, 2); //截取用户id的前两位
   
    console.log("前两位",prefix)
    if(prefix=='10'){
      if(this.userpost=="后端管理员"){
        this.showEventInfo1=true 
        this.showEventInfo2=true 
        this.showEventInfo3=true 
        this.showEventInfo4=true 
        this.showEventInfo5=true 
        this.showEventInfo6=true
        
      }else if(this.userpost=="青训负责人"){
        //是否操作按钮的权利
      }else if(this.userpost=="教练负责人"){
        //操作的权利
      }else if(this.userpost=="教练负责人"){
      //操作的权利
      }else if(this.userpost=="俱乐部负责人"){
        //操作的权利
      }else if(this.userpost=="用户负责人"){
        //操作的权利
      }
    }else if (prefix=='30') {
      //运动员
      this.showEventInfo1=true 
        this.showEventInfo2=false
        this.showEventInfo3=true 
        this.showEventInfo4=true 
        this.showEventInfo5=false 
       
     
    }else if (prefix=='20') {
      this.showEventInfo1=true 
        this.showEventInfo2=true 
        this.showEventInfo3=true 
        this.showEventInfo4=true 
        this.showEventInfo5=false 
      console.log("别的权限")
   }else if (prefix=='70') {
    this.showEventInfo1=false 
        this.showEventInfo2=false
        this.showEventInfo3=false 
        this.showEventInfo4=false 
        this.showEventInfo5=false 
        this.showEventInfo6=false 
      console.log("别的权限")
   }
},
},
mounted() {
  this.userinfow()
  
},
created() {
  this.userinfow()
},
}
</script>

<style scoped></style>