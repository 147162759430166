<template>
    <div class="waicheng" style="background-color: #ececec; ">

        <div  class="histograms" style="background: #fff; margin-left: 20px; border-radius: 10px ;">
            <div id="histogram" ref="histogram">
            </div>
        </div>
        <div>
            <div style="background: #fff; margin-left: 20px;border-radius: 10px; display: flex; height: 250px;">
                <div id="ple" ref="ple">
                </div>
                <div id="pletow" ref="pletow"></div>
            </div>
            <div class="daiban" style="width:1003px; height: 372px;  margin-left: 20px;  margin-top: 20px;   display: flex;">
               <div  style="margin-right: 10px">
                <tablee />
               </div>
               
                <div style="width:400px; height: 370px; background: #fff; border-radius: 10px; ">
                    <span><h1>公告</h1></span>
                   <div>
                    <noti />
                   </div> 
                </div>
                
            </div>


        </div>

    </div>
</template>
<script>
import * as echarts from 'echarts';
import tablee from './daibantable.vue'
import noti from'./notification.vue'
import axios from 'axios';
export default {
    data() {
        return {
            myCharta: null,
            chartadata: [],
            myChart:null,
            towchar:[],
            Histogram:null,
            hidata:[],

        }
    },
    components: {
        tablee,
        noti,
    },
    methods: {
        sizhibingtu() {
            axios.get('/api/Home/athletes',).then((rel) => {
                console.log(rel.data.data);
                //    this.chartadata=rel.data.data
                this.chartadata = rel.data.data.map(item => ({
                    name: item.name, // 假设后端返回的对象有name属性  
                    value: item.value // 假设后端返回的对象有value属性  
                }));
                this.updateChart(); // 数据加载完成后更新图表  
                // console.log(this.chartadata);
            })
        },



        clubLocal(){
            axios.get('/api/Home/clublocal',).then((rel)=>{
                console.log("传俱乐部地址的")
                this.towchar = rel.data.data.map(item => ({
                    name: item.name, // 假设后端返回的对象有name属性  
                    value: item.value // 假设后端返回的对象有value属性  
                }));
                this.towupdateChart();
            })
        },
        Histdata(){
            axios.get('/api/Home/histogram').then((rel)=>{
                console.log(rel)
               const values=rel.data.data;
               this.uphistogram(values);
            })
          
        },

        //更新柱状图信息
        uphistogram(values){
            if (!this.Histogram) {
                // 如果ECharts实例还未初始化，则初始化它  
                this.Histogram = echarts.init(this.$refs.histogram);
            }
            // 使用新数据更新ECharts选项  
            this.Histogram.setOption({
                title: {
                text: '青训年龄段'
            },
            tooltip: {},
            xAxis: {
                data: ['10-12岁', '13-15岁', '16-18岁', '19-25岁']
            },
            yAxis: {},
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    data: values,
                }
            ]
            });
        },
        //更新第一个饼图信息
        updateChart() {
            if (!this.myCharta) {
                // 如果ECharts实例还未初始化，则初始化它  
                this.myCharta = echarts.init(this.$refs.ple);
            }
            // 使用新数据更新ECharts选项  
            this.myCharta.setOption({
                title: {
                    text: '运动员类型',
                    subtext: '类型分布',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: '人数', // 注意：这里的名称可能与你的数据不匹配，根据需要修改  
                        type: 'pie',
                        radius: '50%',
                        data: this.chartadata, // 使用从API获取的数据  
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        },
        //更细第二个饼图的信息
        towupdateChart() {
            if (!this.myChart) {
                // 如果ECharts实例还未初始化，则初始化它  
                this.myChart = echarts.init(this.$refs.pletow);
            }
            // 使用新数据更新ECharts选项  
            this.myChart.setOption({
                title: {
                text: '福绵区',
                subtext: '俱乐部数量分布',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
                {
                    name: '俱乐部数量',
                    type: 'pie',
                    radius: '50%',
                    data:this.towchar,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
            });
        }

    },


    mounted() {
        this.sizhibingtu(); // 组件挂载后立即请求数据  
        this.clubLocal();
        this.Histdata();
    },
    created() {
        // this.sizhibingtu(),
        // this. clubLocal()
    },
}
</script>

<style>
.waicheng {
    display: flex;
    width: 1570px;
    height:650px;
}

#ple {

    width: 500px;
    height: 300px;
}

#pletow {

    width: 500px;
    height: 300px;
}

#histogram {

    width: 500px;
    height: 600px;
}

.histograms{
    width: 500px;
    height: 640px;
}
</style>