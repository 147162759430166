<template>

  <div class="one">
    <el-scrollbar height="800px">
    <div class="block">
      <el-carousel height="600px">
        <el-carousel-item v-for="item in imgarray" :key="item.id">
          <img :src="item.imgsrc" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="battlearray">
      <div class="battletitle">
        <div class="text">
          <h1>球队阵容</h1>
        </div>
      </div>
      <div class="car">
        <div  class="carss" style="display: flex; ">
          <div class="imgs" style="margin: 50px;">
          <img src="../../assets/397dcd18c6cc1a4.jpg" alt="" style="width: 100%; height: 100%; ">
          <div style="width: 100%; height: 20%;   position: relative;
            left: 0;top: -125px; color:aliceblue; text-align: center;  background-color: rgba(128, 128, 128, 0.5);"><P style="margin: 25px;"><h1>西热力江</h1></P></div>
        </div>

        <div class="imgs" style="margin-right: 100px;margin-left: 300px; margin-top: 50px;">
          <img src="../../assets/4084f09b17ce30a.jpg" alt="" style="width: 100%; height: 100%;  ">
          <div style="width: 100%; height: 20%;   position: relative;
            left: 0;top: -125px; color:aliceblue; text-align: center;  background-color: rgba(128, 128, 128, 0.5);"><P style="margin: 25px;"><h1>林威</h1></P></div>
        </div>

        <div class="imgs" style="margin-right: 100px;margin-left: 300px; margin-top: 50px;" >
          <img src="../../assets/7019df31357298d.jpg" alt="" style="width: 100%; height: 100%; ">
          <div style="width: 100%; height: 20%;   position: relative;
            left: 0;top: -125px; color:aliceblue; text-align: center;  background-color: rgba(128, 128, 128, 0.5);"><P style="margin: 25px;"><h1>王兰钦</h1></P></div>
        </div>

        </div>
      </div>



    </div>

  </el-scrollbar>
  </div>

</template>

<script>
export default {
  data() {
    return {
      imgarray: [
        { id: 0, imgsrc: require("@/assets/tongxione.jpg") },
        { id: 1, imgsrc: require("@/assets/tongxitow.jpg") },
        { id: 2, imgsrc: require("@/assets/tongxithree.jpg") },
      ],
    }
  },

}
</script>

<style scoped>
.battlearray {
  width: 100%;
  height: 600px;
}

.battletitle {
  width: 100%;
  height: 100px;
  
  background: #F2F3F5;
  Text-align: center
}

.text {
  margin: 20px;
}

.car {
  width: 100%;
  height: 600px;
  background-image: url(../../assets/s-banner.jpg);
  z-index: 1;
}

.battletitle h1 {
  font-size: 50px
}

.imgs {
  width: 18%;
  height: 500px;
  

}
</style>