<template>
  <!-- <h1>公告</h1></h1> -->
  <div class="main">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%" height="700">
        <el-table-column prop="noticeid" label="公告编号" width="180" header-align="center" align="center" />
        <el-table-column prop="noticehead" label="公告标题" width="180" header-align="center" align="center" />
        <el-table-column prop="noticontent" label="公告内容" width="180" header-align="center" align="center" />
        <el-table-column prop="noticeadmin" label="公告发布者" width="180" header-align="center" align="center" />
        <el-table-column prop="priority" label="优先级" width="180" header-align="center" align="center" />
        <el-table-column prop="time" label="发布时间" width="180" header-align="center" align="center" />
        <el-table-column>
          <template #header>
            <div style="display: flex;">
              <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                <Search />
              </el-icon>
              <input type="text"
                style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;"
                v-model="seaid">
              <el-button
                style="border: 1px solid #000; width: 100px; height: 30px; margin-left: 30px; border-radius: 10px;"
                v-on:click="addDialog = true">
                添加
              </el-button>
            </div>
          </template>
          <template #default="scope">
            <el-button v-if="showEventInfo1" plain size="small" @click="handleEdit(scope.$index, scope.row)"
              v-on:click="dialogFormVisible = true">
              编辑
            </el-button>
            <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
              plain v-on:click="centerDialogVisible = true">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="tanchuang">
      <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
        <el-form :model="form" class="for">
          <el-form-item label="公告标题">
            <el-input v-model="noticehead" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="公告内容">
            <el-input v-model="noticontent" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="优先级">
            <el-input v-model="priority" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false"> 取消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>

      <div class="deleteDialog">
        <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
          <span>
            是否确定删除此条数据
          </span>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="centerDialogVisible = false">否</el-button>
              <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                是
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>

      <div class="addDialog">
        <el-dialog v-model="addDialog" title="添加公告" width="500" :before-close="handleClose">
          <div class="input-container">
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">公告标题：</div><el-input v-model="noticeheadAdd" placeholder="公告标题"
                style="width: 200px;"></el-input>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">公告内容：</div><el-input v-model="noticontentAdd" placeholder="公告内容"
                style="width: 200px;"></el-input>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">发布时间：</div>
              <div class="block">
                <el-date-picker v-model="value2" type="datetime" placeholder="Pick a Date" format="YYYY/MM/DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss" />
              </div>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 113px;"> 发布者：</div><el-input v-model="noticeadminAdd" placeholder="发布者"
                style="width: 200px;"></el-input>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 113px;">优先级：</div><el-input v-model="priorityAdd" placeholder="优先级"
                style="width: 200px;"></el-input>
            </div>
          </div>

          <template #footer>
            <div class="dialog-footer">
              <el-button @click="addDialog = false">取消</el-button>
              <el-button type="primary" @click="addevnt()">
                确定
              </el-button>
            </div>
          </template>
        </el-dialog>


      </div>


    </div>
    <div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

  </div>
</template>
<script setup>
import {
  Search, Plus, Refresh
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
const dialogFormVisible = ref(false)
const centerDialogVisible = ref(false)
</script>

<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
export default {

  data() {
    return {
      tableData: [],
      currentPage: 1,  //当前页
      pageSize: 10, //页面数据条数
      total: '100',
      priority: "",//优先级
      noticontent: '',//内容
      noticeadmin: '',//发布人
      noticeid: '',//公告id
      time: '',//发布时间
      noticehead: '',//标题
      showEventInfo1: false,
      addDialog: false,
      value2: '',
      noticeheadAdd: '',
      noticontentAdd: '',
      noticeadminAdd: '',
      priorityAdd: '',
      combinedDateTime: null,
    }
  },

  methods: {

    //换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.info()
    },
    //换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.info()
    },
    //获取用户信息
    info() {
      axios.get(`/api/notice/noticeinfo?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data.records
        this.currentPage = rel.data.data.current
        this.pageSize = rel.data.data.size
        this.total = rel.data.data.total
      })
    },
    //上传图片完成回调
    //  handleAvatarSuccess(res, file) {
    //    this.sqlimg = res.data;
    //    console.log(this.sqlimg)
    //    this.imageUrl = URL.createObjectURL(file.raw);
    //    console.log(this.imageUrl)
    //  },
    //  //上传图片过滤
    //  beforeAvatarUpload(file) {
    //    const isJPG = file.type === 'image/jpeg';
    //    const isLt2M = file.size / 1024 / 1024 < 2;

    //    if (!isJPG) {
    //      this.$message.error('上传头像图片只能是 JPG 格式!');
    //    }
    //    if (!isLt2M) {
    //      this.$message.error('上传头像图片大小不能超过 2MB!');
    //    }
    //    return isJPG && isLt2M;
    //  },
    //用户信息回显
    handleEdit(number, User) {
      console.log(number, User)
      this.priority = User.priority
      this.noticontent = User.noticontent
      this.noticehead = User.noticehead
      this.noticeid = User.noticeid
      this.noticeadmin = User.noticeadmin
      //  this.imageUrl = `/api/common/download?name=${User.img}`
    },
    //编辑
    editplay() {
      axios.put('/api/notice/upnotice', {
        priority: this.priority,
        noticontent: this.noticontent,
        noticehead: this.noticehead,
        noticeid: this.noticeid,
        noticeadmin: this.noticeadmin,
      }).then((rel) => {
        alert("修改成功")
        this.info()
      })
    },
    //查询方法
    Searchqinxun() {
      const id = this.noticeid
      axios.get(`/api/user/seleuserid?id=${id}`).then((rel) => {
        console.log(rel);
        this.tableData = rel.data.data
      })
    },
    // 删除方法
    deleteat() {
      const id = this.noticeid
      axios.delete(`/api/notice/denotice?id=${id}`).then((rel) => {
        console.log(rel);
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        this.info()
      })
    },
    //删除按钮行数选定
    handleDelete(index, User) {
      console.log(index, User)
      this.noticeid = User.noticeid

    },
    //时间转换
    // combineDateTime() {  
    //   if (this.date1 && this.date2) {  
    //     const datePart = new Date(this.date1.replace(/-/g, '/')); // 将日期字符串转换为 Date 对象  
    //     const timePart = this.date2.split(':'); // 将时间字符串分割为数组  

    //     // 创建一个新的 Date 对象，设置日期和时间  
    //     const combinedDate = new Date(datePart);  
    //     combinedDate.setHours(parseInt(timePart[0], 10));  
    //     combinedDate.setMinutes(parseInt(timePart[1], 10));  
    //     combinedDate.setSeconds(parseInt(timePart[2], 10) || 0); // 如果没有秒，则默认为0  

    //     // 如果你需要将这个 Date 对象转换为 LocalDateTime（Java 8），你需要在后端处理  
    //     // 这里只是前端处理，所以我们将它存储为 JavaScript Date 对象  
    //     this.combinedDateTime = combinedDate;  

    //     // 你可以在这里发送这个日期到后端，例如通过 API 请求  
    //     console.log(this.combinedDateTime);  
    //   } else {  
    //     console.error('请确保日期和时间都已选择');  
    //   }  
    // },  




    // upimg(){
    //   axios.post('/api/common/upload',{

    //   }).then((rel)=>{
    //     console.log(rel)
    //   })
    // },

    // 当文件状态发生变化时触发，此处用于更新预览图片  
    //身份认证
    userpost() {
      const posts = localStorage.getItem("posts")
      console.log("身份验证", posts)
      if (posts == "后端管理员") {
        console.log("身份验证成功")
        this.showEventInfo1 = true;
      } else {
        console.log("身份验证结束")
        this.showEventInfo1 = false;
      }
    },

    addevnt() {
      axios.post('/api/notice/noticeadd', {
        noticehead: this.noticeheadAdd,//赛事名称
        noticontent: this.noticontentAdd,//赛事负责人
        noticeadmin: this.noticeadminAdd,//比赛时间
        priority: this.priorityAdd,//赞助商
        time: this.value2,
      }).then((rel) => {
        if (rel.data.code == 200) {
          ElMessage({
            message: '成功',
            type: 'success',
          })
          this.addDialog = false
          this.info()
        } else {
          ElMessage({
            message: '失败',
            type: 'error',
            plain: true,
          })
          this.addDialog = false
        }

      })
    }



  },


  created() {
    this.info();
    this.userpost()
  },



}
</script>

<style scoped></style>