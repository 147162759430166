<template>
  <div class="statisticwaicheng" style="background-color: #ececec; padding: 20px;  ">
    <a-row :gutter="16">
      <a-col :span="12">
        <a-card >
            <a-col :span="50">
            <a-statistic title="福绵区俱乐部数量" :value="clubnb" style="margin-right: 50px" />
        </a-col>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card >
            <a-col :span="50">
            <a-statistic title="青训人才库" :value="numa" />
        </a-col>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<style>
</style>
<script>
import axios from 'axios'
export default {
data() {
  return {
    clubnb:'',
    numa:'',
  }
},
methods: {
  num(){
    axios.get('/api/Home/nub',).then((rel)=>{ 
      console.log(rel);
    //   const data = rel.data.data; // 直接从 response 中获取 data  
    // const qinxunCount = data['qinxun'];  
    // const clubCount = data['club'];  
  
    // console.log('青训人才数:', qinxunCount);  
    // console.log('俱乐部数:', clubCount);  
      this.clubnb=rel.data.data['club']
      this.numa=rel.data.data['qinxun']
      console.log(rel.data.data['qinxun'])
    })
  }
},

mounted() {
  this.num()
},
}
</script>

<style>
.statisticwaicheng{
  height: 154px;
  width: 1570px;
}
</style>