<template>  
 
    <div class="mianHome">
      <div class="st">
        <statistic />
      </div>
      <div class="pe">
        <pie />
      </div>
    </div>
    
</template>  
  
<script>  
import pie from '../../components/pie.vue'
import statistic from '../../components/Statistic.vue'
export default {  
  components: {
    pie,
    statistic,
  },

  mounted() {  
    // this.$store.dispatch('fetchUserInfo'); // 组件挂载后调用action  
  }  
}  
</script>  
  
<style>  
.mianHome{
  margin: 10px;
  max-width: 1500px;
}

</style>