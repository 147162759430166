<template>
  <div ref="chartDom"
    style="width: 240px; height: 230px; background-color: #fff; margin-left: 4px; border-radius: 10px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'GaugeChart',
  props: ['message'],
  data() {
    return {
      chart: null,
      option:{
        tooltip: {
          formatter: '' // 这里可能需要更合适的文本  
        },
        series: [
          {
            type: 'gauge',
            startAngle: 400,
            endAngle: 0,
            center: ['50%', '50%'],
            radius: '90%',
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            axisLabel: {
              color: '#464646',
              fontSize: 10,
              distance: 10,
              rotate: 'tangential',
              formatter: function (value) {
                if (value === 0.875) {
                  return '优秀';
                } else if (value === 0.625) {
                  return '良好';
                } else if (value === 0.375) {
                  return '及格';
                } else if (value === 0.125) {
                  return '不及格';
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '+20%'],
              fontSize: 20
            },
            detail: {
              fontSize: 30,
              offsetCenter: [0, '+1%'],
              valueAnimation: true,
              formatter: function (value) {
                return Math.round(value * 100) + '';
              },
              color: 'inherit'
            },
            data: [
              {
                value: this.message.value,//数据源
                name: this.message.name
              }
            ]
          }
        ]
      }
    };
  },
  watch: {
    // 观察message的变化，并据此更新图表  
    message: {
      handler(newVal) {
        console.log(newVal)
        // 当chartData变化时，更新currentChartData并重新渲染图表  
        this.option.series[0].data[0] = {
          value: newVal.value,
          name: newVal.name
        }; // 假设newVal是完整的图表配置或数据  
        this.chart.setOption(this.option);
      },
      deep: true // 开启深度监听  
    }
  },
  mounted() {
    this.initChart();
    console.log(this.message)
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose(); // 销毁图表实例，避免内存泄漏  
    }
  },
  methods: {
    initChart() {
      // 基于准备好的dom，初始化echarts实例  
      this.chart = echarts.init(this.$refs.chartDom);
      // 使用刚指定的配置项和数据显示图表。  
      this.chart.setOption(this.option);
    }
  }
};

</script>

<style></style>