<template>
  <el-tabs v-loading="loading" element-loading-text="正在修改" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" type="border-card" v-model="activeName" class="demo-tabs"
    style="margin-left: 50px; width: 1000px; height: 800px; ">
    <el-tab-pane label="教练" name="coach" style="font-size: small; margin-right: 600px; ">
      <div class="coachform" >
         <caadd />
      </div>
    
    </el-tab-pane>

    <el-tab-pane label="运动员" name="ath" style="font-size: small; margin-right: 600px;">
     <atadd />
    </el-tab-pane>
    <el-tab-pane label="用户" name="user" style="font-size: small; margin-right: 600px;">
      <useradd />
    </el-tab-pane>

    <el-tab-pane label="俱乐部" name="club" style="font-size: small; margin-right: 600px;">
      <clubadd />
    
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import caadd from '../../components/coachadd.vue'
import atadd from '../../components/athadd.vue'
import useradd from '../../components/useradd.vue'
import clubadd from '../../components/clubadd.vue'
export default {
data() {
  return {
    
  }
},

components:{
    caadd,
    atadd,
    useradd,
    clubadd,
}


}



</script>

<style></style>