<!-- Header.vue -->
<template>
    <div class="aa">
        <el-header
            style="height: 60px; display: flex; align-items: center; box-shadow: 2px 0 6px rgba(0, 21, 41, .35);">
            <!-- 侧边栏展开收缩！！collapseIcon：点击侧边栏图标样式  handleCollapse：绑定了图标点击事件（脚本）  还有图标大小为30px-->
            <i :class="collapseIcon" @click="handleCollapse" style="font-size: 30px"></i>
            <!-- 绑定字体距离图标的距离、还有点击图标跳转的路由 -->
            <el-breadcrumb separator="/" style="margin-left: 20px">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">课程管理</el-breadcrumb-item>
            </el-breadcrumb>


            <div>

             
            </div>
            <!--点击收缩栏时候的头部弹性布局，justify-content：推向右边 。align-items：内容居中 -->
            <div style="flex: 1; display: flex; justify-content: flex-end; align-items: center">
                <!-- placement="bottom"：按钮触发下方弹出  cursor: pointer：鼠标放到这里变成一个手指-->
                <el-dropdown placement="bottom">
                    <div style="display: flex; align-items: center; cursor: pointer">
                        <!-- 图标的设置和宽度 -->
                        <!-- <img :src="require('@/assets/logo.png')" style="width: 30px;" alt=""> -->
                        <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
                        <span>{{ username }}</span>
                    </div>
                    <!-- 顶部的触碰显示下来信息 -->
                    <template #dropdown>
                        <el-dropdown-menu>
                            <router-link to="/employee" style="text-decoration: none">
                                <el-dropdown-item>个人信息</el-dropdown-item>
                            </router-link>
                            <router-link to="/" style="text-decoration: none" @click="loginout">
                                <el-dropdown-item>退出登录</el-dropdown-item></router-link>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </el-header>
    </div>
</template>
<script setup>
import {
 Search, Plus, Refresh
} from '@element-plus/icons-vue'
</script>
<script>
import axios from 'axios';
export default {

    data() {
        return {
            name: 'Header',
            props: {
                collapseIcon: String,
                handleCollapse: Function
                // 其他需要的 props
            },
            username: '请重新登录',
            userinfolist: [],
            posts: '',
        }




    },
    methods: {
        //登录信息请求
        loginInfoApi() {
            axios.get('/api/userinfotow',).then((rel) => {
                console.log(rel.data);
                this.username = rel.data.data.name
                this.posts = rel.data.data.post
                localStorage.setItem('posts', this.posts);
            })
        },

        quanx() {
            axios.get('/api/Home/quanxian',).then((rel) => {
                console.log(rel.data);

            })
        },

        loginout() {
            axios.get('/api/loginout',).then((rel) => {
                alert("退出成功")
                localStorage.clear();
            })
        }

    },



    mounted() {
        this.loginInfoApi()
    },
    created() {

        this.quanx();
    },
    // 其他逻辑和方法
};


</script>

<style></style>