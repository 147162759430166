<template>
    <div class="input-container">
        <div class="input-wrapper">LOGO： <div>
                <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
                    :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                    :on-change="handleFileChange" :auto-upload="false">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <button @click="submitUpload()" type="button">上传图片</button>
            </div>
        </div>
        <div class="input-wrapper">姓名：<el-input v-model="name" placeholder="俱乐部名称" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">地址：<el-input v-model="address" placeholder="地址" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">负责人：<el-input v-model="principal" placeholder="负责人" style="width: 200px;"></el-input>
        </div>
    </div>
    <div class="butt">
        <button @click="clubadd()"
            style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin: 40px">添加俱乐部</button>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios';
export default {
    data() {
        return {
            imageUrl: '',
            sqlimg: '',
            name: '',
            address:'',
            principal:''
        }
    },

    methods: {
        //上传图片完成回调
        handleAvatarSuccess(res, file) {
            this.sqlimg = res.data;
            console.log(this.sqlimg)
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(this.imageUrl)
        },
        //上传图片过滤
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        //编辑上传图片变换
        handleFileChange(file, fileList) {
            this.imageUrl = URL.createObjectURL(file.raw);
            if (fileList.length > 0) {
                this.fileList = [fileList[fileList.length - 1]]
            }

            // 当文件状态发生变化时触发，此处用于更新预览图片  
        },
        getImage(image) {//图片遍历
            return `/api/common/download?name=${image}`
        },
        submitUpload() {
            this.$refs.uploadRef.submit();
        },

        clubadd() {
            axios.post('/api/club/clubadd', {
                name: this.name,//姓名
                img: this.sqlimg,//头像
                address:this.address,
                principal:this.principal
            }).then((rel) => {
                if (rel.data.code == 200) {
                    ElMessage({
                        message: '成功',
                        type: 'success',
                    })
                   
                } else {
                    ElMessage({
                        message: '失败',
                        type: 'error',
                        plain: true,
                    })
                }

            })
        }

    },

    mounted() {

    },

}
</script>

<style></style>