<template>

  <!-- <h1>赛事管理</h1> -->
  <div class="main">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%" height="700">
        <el-table-column prop="comid" label="赛事ID" width="180" header-align="center" align="center" />
        <el-table-column prop="comname" label="赛事名称" width="180" header-align="center" align="center" />
        <el-table-column prop="img" label="赛事海报" width="180" header-align="center" align="center">
          <template #default="scope">
            <el-image :src="getImage(scope.row.img)" style="width:100px ;"
              preview-src-list="=[ `/aip/common/download?name=${scope.row.image}` ]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="regtime" label="报名时间" width="180" />
        <el-table-column prop="time" label="报名结束" width="180" header-align="center" align="center" />

        <el-table-column prop="sponsor" label="赞助商  " width="180" header-align="center" align="center" />
        <el-table-column prop="adminname" label="负责人" width="180" header-align="center" align="center" />
        <el-table-column prop="comstate" label="报名状态" width="90" header-align="center" align="center">
          <template v-slot="scope">
            {{ scope.row.comstate === 0 ? '正在报名' : '禁止报名' }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            <div style="display: flex;">
              <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                <Search />
              </el-icon>
              <input type="text"
                style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;"
                v-model="seaid">
              <el-button
                style="border: 1px solid #000; width: 100px; height: 30px; margin-left: 30px; border-radius: 10px;"
                v-on:click="addDialog = true">
                添加
              </el-button>

            </div>
          </template>
          <template #default="scope">
            <el-button plain size="small" @click="baoming(scope.row.comstate, scope.row.comid)">
              {{ scope.row.comstate === 0 ? '禁止报名' : '正在报名' }}
            </el-button>
            <el-button plain size="small" @click="handleEdit(scope.$index, scope.row)"
              v-on:click="dialogFormVisible = true">
              编辑
            </el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)" plain
              v-on:click="centerDialogVisible = true">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="tanchuang">
      <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
        <el-form :model="form" class="for">
          <el-form-item>
            <div>
              <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
                :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                :on-change="handleFileChange" :auto-upload="false">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <button @click="submitUpload()" type="button">上传图片</button>
            </div>
          </el-form-item>
          <el-form-item label="赛事名称">
            <el-input v-model="comname" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="报名时间">
            <el-input v-model="regtime" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="报名结束">
            <el-input v-model="time" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="赞助商">
            <el-input v-model="sponsor" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="负责人">
            <el-input v-model="adminname" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>

          <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
           <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
         </el-form-item> -->
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false"> 取消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>

      <div class="deleteDialog">
        <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
          <span>
            是否确定删除此条数据
          </span>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="centerDialogVisible = false">否</el-button>
              <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                是
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>

      <div class="addDialog">
        <el-dialog v-model="addDialog" title="添加赛事" width="500" :before-close="handleClose">
          <div class="input-container">
            <div class="input-wrapper">赛事海报 <div>
                <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
                  :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                  :on-change="handleFileChange" :auto-upload="false">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <button @click="submitUpload()" type="button">上传图片</button>
              </div>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">赛事名称：</div><el-input v-model="comname" placeholder="赛事名称"
                style="width: 200px;"></el-input>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 113px;">负责人：</div><el-input v-model="adminname" placeholder="负责人"
                style="width: 200px;"></el-input>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">比赛时间：</div>
              <el-col :span="11">
                <el-date-picker v-model="date1" type="date" placeholder="选择时间" style="width: 100%" />
              </el-col>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 113px;"> 赞助商：</div><el-input v-model="sponsor" placeholder="赞助商"
                style="width: 200px;"></el-input>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">报名时间：</div>
              <el-col :span="11">
                <el-date-picker v-model="regtime" type="date" placeholder="选择时间" style="width: 100%" />
              </el-col>
            </div>
            <div class="input-wrapper" style="display: flex;">
              <div style="margin-left: 100px;">赛事状态：</div><el-input v-model="comstateadd" placeholder="赛事状态"
                style="width: 200px;"></el-input>
            </div>
          </div>

          <template #footer>
            <div class="dialog-footer">
              <el-button @click="addDialog = false">取消</el-button>
              <el-button type="primary" @click="addevnt()">
                确定
              </el-button>
            </div>
          </template>
        </el-dialog>


      </div>

    </div>
    <div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

  </div>
</template>
<script setup>
import {
  Search, Plus, Refresh
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
const dialogFormVisible = ref(false)
const centerDialogVisible = ref(false)

</script>

<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
export default {

  data() {
    return {
      tableData: [],
      currentPage: 1,  //当前页
      pageSize: 10, //页面数据条数
      total: '100',
      imageUrl: '',//编辑照片显示路径
      comname: '',
      comid: '',
      regtime: '',
      time: '',
      sponsor: '',
      adminname: '',
      sqlimg: '',
      seaid: '',
      comstates: '',
      dialogVisible: false,
      date1: '',
      type: [],
      comstateadd:'',
      addDialog:false,

    }
  },

  methods: {

    //换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.info()
    },
    //换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.info()
    },
    //获取赛事信息
    info() {
      axios.get(`/api/competition/competitioninfo?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data.records
        this.currentPage = rel.data.data.current
        this.pageSize = rel.data.data.size
        this.total = rel.data.data.total
      })
    },
    //上传图片完成回调
    handleAvatarSuccess(res, file) {
      this.sqlimg = res.data;
      console.log("图片名称", this.sqlimg)
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl)
    },
    //上传图片过滤
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //赛事信息回显
    handleEdit(number, User) {
      console.log(number, User)
      this.comid = User.comid
      this.comname = User.comname
      this.regtime = User.regtime
      this.time = User.time
      this.sponsor = User.sponsor
      this.adminname = User.adminname
      this.imageUrl = `/api/common/download?name=${User.img}`
    },
    //编辑
    editplay() {
      axios.put('/api/competition/Upcompetition', {
        comid: this.comid,
        comname: this.comname,
        adminname: this.adminname,
        sponsor: this.sponsor,
        time: this.time,
        regtime: this.regtime,
        img: this.sqlimg,
      }).then((rel) => {
        ElMessage({
          message: '修改成功',
          type: 'success',
        })
        this.info()
      })
    },

    costate(comstates, id) {
      axios.put('/api/competition/Upstact', {
        comid: id,
        comstate: comstates
      }).then((rel) => {
        ElMessage({
          message: '成功',
          type: 'success',
        })
        this.info()
      })
    },
    baoming(s, comid) {
      console.log(s)
      console.log(comid)
      if (s == 1) {
        this.comstates = 0
        this.costate(this.comstates, comid)
      } else if (s == 0) {
        this.comstates = 1
        this.costate(this.comstates, comid)
      }

    },
    //查询方法
    Searchqinxun() {
      const id = this.seaid
      axios.get(`/api/competition/Search?id=${id}`).then((rel) => {
        console.log(rel);
        this.tableData = rel.data.data
      })
    },
    // 删除方法
    deleteat() {
      const id = this.comid
      axios.delete(`/api/competition/decompetition?id=${id}`).then((rel) => {
        console.log(rel);
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        this.info()
      })
    },
    //上传文件提交选定表
    submitUpload() {
      this.$refs.uploadRef.submit();
    },
    //删除按钮行数选定
    handleDelete(index, User) {
      console.log(index, User)
      this.comid = User.comid

    },

    // upimg(){
    //   axios.post('/api/common/upload',{

    //   }).then((rel)=>{
    //     console.log(rel)
    //   })
    // },
    //编辑上传图片变换
    handleFileChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }

      // 当文件状态发生变化时触发，此处用于更新预览图片  
    },
    //身份认证
    userpost() {
      const posts = localStorage.getItem("posts")
      console.log("身份验证", posts)
      if (posts == "后端管理员" || posts == "教练负责人") {
        console.log("身份验证成功")
        this.showEventInfo1 = true;
      } else {
        console.log("身份验证结束")
        this.showEventInfo1 = false;
      }
    },
    //筛选
    condition(s) {
      axios.post('/api/coach/condition', {
        inputclub: this.inputintroduction,
        inputpost: this.inputpost,
        inputphone: this.inputphone,
        name: this.inputname,
        ctype: s,
      }).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data
      })
    },
    getImage(image) {//图片遍历
      return `/api/common/download?name=${image}`
    },

    addevnt() {
      axios.post('/api/competition/addcompetition', {
        comname: this.comname,//赛事名称
        adminname: this.adminname,//赛事负责人
        time: this.date1,//比赛时间
        sponsor: this.sponsor,//赞助商
        regtime: this.regtime,//报名时间
        comstate: this.comstateadd,//报名状态
        img: this.sqlimg,//头像
      }).then((rel) => {
        if (rel.data.code == 200) {
          ElMessage({
            message: '成功',
            type: 'success',
          })
          this.addDialog = false
          this.info()
        } else {
          ElMessage({
            message: '失败',
            type: 'error',
            plain: true,
          })
         this.addDialog = false
        }

      })
    }
  },

  created() {
    this.info();
    //  this.userpost()
  },



}
</script>

<style>
.head {
  border: 1px solid #000;
  width: 100%;
  height: 50px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.for {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 垂直居中 */
  /* 注意：这种方式会影响所有表单项，包括它们的标签 */
}
</style>