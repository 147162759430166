<template>

  <div style="background: #102749; width: 100%; height: 15%; margin-bottom: 2%; ">

    <span style=" margin: 50%;">
      <h1 style="color: aliceblue; ">赛事</h1>
    </span>

  </div>

  <div class="a">
    <div class="carb" style="display: flex;">

      <el-card style="width: 300px;margin: 10px;" v-for=" item in com" :key="item.comid">
        <template #header>
          <div class="card-header">
            <span>{{ item.comname }}</span>
          </div>
        </template>
        <div class="imgs" style="width: 100%; height: 100%;">
          <img :src="getImage(item.img)" preview-src-list="=[ `/aip/common/download?name=${item.img}` ]"
            style="width: 100%; height: 300px;">
        </div>
        <template #footer>
          <el-button plain @click="baoming(item.comname)" :disabled="!item.comstate == 0">
            {{ item.comstate === 0 ? '报名' : '禁止报名' }}
          </el-button>
          <el-button plain @click="this.dialogVisibleb = true">
            详情
          </el-button>
        </template>
      </el-card>

      <!-- /报名弹窗 -->
      <el-dialog v-model="dialogVisible" title="报名" width="500" :before-close="handleClose">
        <div style="display: flex;"><label style="margin-right: 2%;">俱乐部名称：</label><el-input v-model="inputa"
            placeholder="请输入内容"></el-input></div>
        <div style="display: flex;"><label>俱乐部负责人：</label><el-input v-model="inputb" placeholder="请输入内容"></el-input>
        </div>
        <!-- <div style="display: flex;"><label style="margin-right: 5%;">参赛人数：</label><el-input v-model="inputc"
            placeholder="请输入内容"></el-input></div> -->
        <div style="display: flex;"><label style="margin-right: 5%;">联系电话：</label><el-input v-model="inputf"
            placeholder="请输入内容"></el-input></div>
        <div style="display: flex;"><label style="margin-right: 7%;">参赛押金:</label><el-input v-model="inputd"
            placeholder="请输入内容" :disabled="true"></el-input></div>
        <div style="display: flex;"><label style="margin-right: 7%;">赛事名称:</label><el-input v-model="inpute"
            placeholder="请输入内容" :disabled="true"></el-input></div>

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addinfo()">
              确定
            </el-button>
          </div>
        </template>
      </el-dialog>

      <!-- //详情弹窗 -->
      <el-dialog v-model="dialogVisibleb" title="详情" width="500" :before-close="handleClose">
        <span>具体详情请联系相关部门进行咨询。联系方式：1554525454545</span>
        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary" @click="dialogVisibleb = false">
              确定
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>



  </div>
</template>


<script setup>
import { ElMessage } from 'element-plus'
</script>




<script >
import axios from 'axios';
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleb: false,
      inputa: '',
      inputb: '',
      inputc: '',
      inputd: '1000',
      com: [],
      inpute: '',
      inputf: '',
    }
  },


  methods: {
    selesaishi() {
      axios.get('/api/competition/selectcompetition').then((rel) => {
        console.log(rel);
        if (rel.data.code == 200) {
          this.com = rel.data.data
          console.log(this.com);
        } else {
          this.$message.error('查询问题，请刷新，或重新登录');
        }

      })
    },


    addath() {
      axios.post('/api/competition/addath', {
        clubname: this.inputa,
        principal: this.inputb,
        money: this.inputd,
        competitionname: this.inpute,
        phone: this.inputf,
      }).then((rel) => {
        console.log(rel);
        if (rel.data.code == 200) {
          ElMessage({
            message: '报名成功',
            type: 'success',
          })
        } else {
          this.$message.error('报名失败，请致电：17778454645');
        }

      })
    },

    getImage(image) {//图片遍历
      return `/api/common/download?name=${image}`
    },

    baoming(com) {
      this.dialogVisible = true;
      this.inpute = com;
    },

    addinfo() {
      this.dialogVisible = false
      this.addath()
    }



  },

  created() {
    this.selesaishi()
  }
}
</script>

<style scoped></style>