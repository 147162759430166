<template>
    <!-- 运动分析 -->
    <div class="waichengan" style="width: 100%; height: 89vh; display: flex; background-color: #ececec;">
        <div class="renti" style="width: 25%; height: 100%;  background: #fff;">

            <div class=" info" style="width: 100%; height: 33%; display: flex;">
                <div class="img" style="width: 50%; height: 100%; "> <img src="../../assets/renti(1).png" alt=""
                        style="width: 50%; height: 100%;"></div>
                <div class="infoname" style="width: 50%;height: 100%;">

                    <p style="text-align:left"> 编号：<el-select filterable allow-create default-first-option
                            v-model="valueid" placeholder="Select" size="large" style="width: 100px">
                            <el-option v-for="item in options" :key="item.userid" :label="item.userid"
                                :value="item.userid" />
                        </el-select>
                    </p>
                    <p style="text-align:left"> 时间：<el-select filterable allow-create default-first-option
                            v-model="valuetime" placeholder="Select" size="large" style="width: 140px">
                            <el-option v-for="item in optime"  :key="item.lotimes" :label="item.lotimes"
                            :value="item.lotimes" />
                              
                        </el-select>
                    </p>
                    <div class="x" v-if="atinfo.length > 0">
                        <p style="text-align: left; width: 150px;">姓名：{{
                            this.atinfo[0].name }}</p>
                        <p style="text-align: left;width: 150px;">年龄：{{ this.atinfo[0].age }}</p>
                        <p style="text-align: left;width: 150px;">身高：{{ this.atinfo[0].height }}</p>
                        <p style="text-align: left;width: 150px;">体重：{{ this.atinfo[0].weight }}</p>
                        <p style="text-align: left;width: 150px;">司职：{{ this.atinfo[0].post }}</p>
                    </div>
                    <div v-else>
                        <p>请选择要分析运动员的编号</p>
                    </div>
                    <div class="butt" style="display: flex;">
                        <el-button type="success" round class="enlarged-button" @click="Totals()">球员分析</el-button>
                        
                    </div>

                </div>
            </div>
            <div class="jirou" style="width: 100%; height: 33%;">
                <div class="ji" style="margin-top: 10%">
                    <bc :tablevalue="bar" />
                </div>
            </div>


        </div>
        <div class="nenlifenxi" style="  width: 75%;">
            <div class="bing" style="display: flex; width: 100%; height: 30%;">
                <div class="bing1" style=" height: 100%; background: #fff;">
                    <at :message="parentMessage" />
                </div>
                <div class="bing1" style="background: #fff;">
                    <at :message="parentMessagetow" />
                </div>
                <div class="bing1" style="background: #fff;">
                    <at :message="parentMessagethree" />
                </div>
                <div class="bing1" style="background: #fff;">
                    <at :message="parentMessagefour" />
                </div>
                <div class="bing1" style="background: #fff;">
                    <at :message="parentMessagefive" />
                </div>
            </div>

            <div class="biaotu"
                style="width: 100%; height: 40%; display: flex; background-color: #fff;border-radius: 5px;">
                <div class="biao"
                    style="width: 70%; height: 100%;  margin-right: 1%; background-color: #fff; border-radius: 5px;">
                    <tables :datatable="tabledatas" />
                </div>
                <div class="map" style=" width: 30%;height: 100%;  border-radius: 5px ; margin-left: 10px;">
                    <rad :message="ramap" />
                </div>
            </div>
            <div class="qita" style="width: 100%; height: 30%; display: flex;">
                <div class="wuxingnenglitu" style="width: 70%; height: 100%;">
                    <stac :message="RecordData" />
                </div>
                <div class="rate" style="width: 30%; height: 100%; background: #fff;">
                    <h2>综合得分：</h2>
                    <div class="ratefenshu">
                        <span>{{ this.all }}</span>
                    </div>
                    <el-rate size="large" v-model="ratevalue" disabled show-score text-color="#ff9900"
                        score-template="{value} points" />

                </div>
            </div>
        </div>

    </div>

</template>

<script>
import at from '../../components/capacity.vue'
import tables from '../../components/captable.vue'
import stac from '../../components/Stackedline.vue'
import rad from '../../components/radarmap.vue'
import bc from '../../components/barchart.vue'
import axios from 'axios'
export default {
    components: {
        at,
        tables,
        stac,
        rad,
        bc
    },
    data() {
        return {
            parentMessage: {
                value: 0, name: "罚球命中率"
            },
            parentMessagetow: { value: 0, name: "两分命中率" },
            parentMessagethree: { value: 0, name: "罚球命中率" },
            parentMessagefour: { value: 0, name: "上肢力量" },
            parentMessagefive: { value: 0, name: "下肢力量" },
            ratevalue: '3.7',
            options: [],
            valueid: '',
            atinfo: [],
            valuetime: '',
            optime: [],
            tabledatas: [],
            ramap: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            all: '',
            bar: [0, 0, 0, 0, 0, 0],
            RecordData: {},

        }
    },

    methods: {
        info() {//球员编号
            axios.get(`/api/athletes/Training`).then((rel) => {
                // console.log(rel)
                this.options = rel.data.data
                // console.log(this.options)
            })
        },

        infotiom() {
            axios.get(`/api/drill/datatiom`).then((rel) => {
                console.log(rel)
                    this.optime = rel.data.data
                   console.log(this.optime)
            })
        },

        //球员信息
        Searchqinxun() {
            const id = this.valueid;
            console.log(id);
            if (id !== null && id !== "") {
                axios.get(`/api/athletes/Search?id=${id}`).then((response) => {
                    const data = response.data.data || []; // 确保 data.data 是一个数组  
                    if (data.length === 0) {
                        this.$message.error('未找到相关信息');
                    } else {
                        this.atinfo = data.map(user => ({
                            name: user.name,
                            age: user.age,
                            height: user.height,
                            weight: user.weight,
                            post: user.post
                        }));
                    }
                }).catch(error => {
                    this.$message.error('查询发生错误');
                });
            } else {
                this.$message.error('查询ID不能为空');
            }
        },
        //饼图
        piedata() {
            axios.post(`/api/drill/pies`, {
                athletesid: this.valueid,
                lotimes: this.valuetime,
            }).then((res) => {
                console.log(res.data.code)
                if (res.data.code == 200) {
                    const threesValue = res.data.data.threes;//三分
                    const twosValue = res.data.data.twos;//两分
                    const freethrowsValue = res.data.data.freethrows;//罚球
                    const benValue = res.data.data.ben;//上肢力量
                    const weightsquatsValue = res.data.data.weightsquats;//下肢力量
                    this.parentMessage.value = threesValue
                    this.parentMessage.name = "三分命中率"
                    this.parentMessagetow.value = twosValue
                    this.parentMessagetow.name = "两分命中率"
                    this.parentMessagethree.value = freethrowsValue
                    this.parentMessagethree.name = "罚球命中率"
                    this.parentMessagefour.value = benValue
                    this.parentMessagefour.name = "上肢力量"
                    this.parentMessagefive.value = weightsquatsValue
                    this.parentMessagefive.name = "下肢力量"
                } else {
                    console.log(res)

                    this.$message.error('请核对查询内容，后台无此数据');
                }

            })
        },

        //表格
        tabledate() {
            axios.post(`/api/drill/drillID`, {
                athletesid: this.valueid,
                lotimes: this.valuetime,
            }).then((res) => {
                if (res.data.code == 200) {
                    console.log(res)
                    this.tabledatas = res.data.data
                    console.log(this.tabledatas)
                } else {
                    this.tabledatas = null
                    this.$message.error('请核对查询内容，表格无此数据');
                }

            })
        },

        //能力图
        nengli() {
            axios.post(`/api/drill/capacity`, {
                athletesid: this.valueid,
                lotimes: this.valuetime,
            }).then((res) => {
                console.log(res)
                if (res.data.code == 200) {
                    const valuesArray = [
                        res.data.data.therrtotal,
                        res.data.data.towtotal,
                        res.data.data.layuphittotal,
                        res.data.data.freetotal,
                        res.data.data.slidesteptotal,
                        res.data.data.shuttleruntotal,
                        res.data.data.benchtotal,
                        res.data.data.weightsquattotal,
                        res.data.data.weightsquattotal / 2,
                    ];
                    this.ramap = valuesArray
                    let total = res.data.data.alltotal
                    this.all = total.toFixed(2);
                    if (this.all >= 60) {
                        if (this.all >= 70) {
                            if (this.all >= 80) {
                                if (this.all >= 90) {
                                    this.ratevalue = 5
                                } else {
                                    this.ratevalue = 4.5
                                }
                            } else {
                                this.ratevalue = 4
                            }
                        } else {
                            this.ratevalue = 3.5
                        }
                    } else {
                        this.ratevalue = 3
                    }
                } else {
                    console.log(res)

                    this.$message.error('请核对查询内容，后台无此数据');
                }


            })

        },

        Physical() {
            axios.post(`/api/drill/Physical`, {
                athletesid: this.valueid,
                lotimes: this.valuetime,
            }).then((res) => {
                if (res.data.code == 200) {
                    this.bar = [
                        res.data.data.BMI,
                        res.data.data.GGJ,
                        res.data.data.MR,
                        res.data.data.MM,
                        res.data.data.TBF,
                        res.data.data.BFP]
                    console.log("bar", this.bar)
                } else {
                    console.log(res)

                    this.$message.error('请核对查询内容，后台无此数据');
                }

            })
        },
        //近五天
        // Record
        Record() {
            axios.post(`/api/drill/Record`, {
                athletesid: this.valueid,
                lotimes: this.valuetime,
            }).then((res) => {
                if (res.data.code == 200) {
                    console.log(res)
                    this.RecordData = res.data.data
                    console.log("RecordData", this.RecordData)
                } else {
                    console.log(res)

                    this.$message.error('请核对查询内容，后台无此数据');
                }

            })
        },

        Totals() {
            this.Searchqinxun()
            this.piedata()
            this.tabledate()
            this.nengli()
            this.Physical()
            this.Record()
        },


    },
    mounted() {
        this.info()
        this.infotiom()
    },
}
</script>
<style scoped>
.ratefenshu {
    margin-top: 50px;
    vertical-align: middle;
    text-align: center;
}

.ratefenshu span {
    font-size: 80px;
    color: #07e925;
    /* 不及格为红，合格为黄，良好为蓝，优秀为绿 */
}

::v-deep .el-rate__icon {
    font-size: 30px;
    /* 增加星星的大小 */
}

.enlarged-button {
    transform: scale(1);
    /* 将按钮放大1.5倍 */
    transform-origin: center;
    /* 确保放大时按钮从中心开始 */
}
</style>