<template>
    <div class="input-container">
        <div class="input-wrapper">照片： <div>
                <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
                    :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                    :on-change="handleFileChange" :auto-upload="false">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <button @click="submitUpload()" type="button">上传图片</button>
            </div>
        </div>
        <div class="input-wrapper">姓名：<el-input v-model="name" placeholder="姓名" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">账号：<el-input v-model="username" placeholder="账号" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">密码：<el-input v-model="password" placeholder="密码" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">状态：<el-input v-model="state" placeholder="状态" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">电话：<el-input v-model="phone" placeholder="电话" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">职位：<el-input v-model="post" placeholder="职位" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">身高：<el-input v-model="athHeight" placeholder="高度" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">体重：<el-input v-model="athWeight" placeholder="体重" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">年龄：<el-input v-model="age" placeholder="年龄" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">俱乐部：<el-input v-model="clubname" placeholder="俱乐部"
                style="width: 200px;"></el-input>
        </div>
    </div>
    <div class="butt">
        <button @click="athadd()"
            style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin: 40px">添加运动员</button>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios';
export default {
    data() {
        return {
            imageUrl: '',
            sqlimg: '',
            name: '',
            username: '',
            password: '',
            state: '',
            phone: '',
            post: '',
            athHeight: '',
            athWeight: '',
            age: '',
            clubname: '',
        }
    },

    methods: {
        //上传图片完成回调
        handleAvatarSuccess(res, file) {
            this.sqlimg = res.data;
            console.log(this.sqlimg)
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(this.imageUrl)
        },
        //上传图片过滤
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        //编辑上传图片变换
        handleFileChange(file, fileList) {
            this.imageUrl = URL.createObjectURL(file.raw);
            if (fileList.length > 0) {
                this.fileList = [fileList[fileList.length - 1]]
            }

            // 当文件状态发生变化时触发，此处用于更新预览图片  
        },
        getImage(image) {//图片遍历
            return `/api/common/download?name=${image}`
        },
        submitUpload() {
            this.$refs.uploadRef.submit();
        },

        athadd() {
            axios.post('/api/athletes/athadd', {
                name: this.name,//姓名
                state: this.state,//状态
                username: this.username,//账号
                userphone: this.phone,//电话号码
                password:this.password,//密码
                post: this.post,//职位
                height: this.athHeight,//教龄
                weight: this.athWeight,//教练
                age: this.age,//教育方向
                clubname: this.clubname,//简介
                img: this.sqlimg,//头像
            }).then((rel) => {
                if (rel.data.code == 200) {
                    ElMessage({
                        message: '成功',
                        type: 'success',
                    })
                   
                } else {
                    ElMessage({
                        message: '失败',
                        type: 'error',
                        plain: true,
                    })
                }

            })
        }

    },

    mounted() {

    },

}
</script>

<style></style>