<template>
  <div ref="chartDom" style="width: 390px;height:470px ;margin-top:80px; "></div>
</template>
<script>
// import { Grid } from 'ant-design-vue';
import * as echarts from 'echarts';
export default {
  name: 'EChartsBarChart',
  props:['tablevalue'],
  data() {
    return {
      option: {
        xAxis: {
          type: 'value'
        },
        grid: {
          left: '1%',
          right: '20%',
          bottom: '1%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: ['体脂率(%)', '体脂肪重量(kg)', '肌肉量(kg)', '肌肉率(%)', '骨骼肌含量(kg)', '质量指数']
        },
        series: [
          {
            data: this.tablevalue,
            type: 'bar'
          }
        ]
      }
    }
  },
  watch: {
    // 观察tablevalue的变化，并据此更新图表  
    tablevalue: {
      handler(newVal) {
        console.log("newVal",newVal)
        // 当chartData变化时，更新currentChartData并重新渲染图表  
        // this.option.series[0].data[0] = {
        //   value: newVal
        // }; // 假设newVal是完整的图表配置或数据  
        this.option.series[0].data = newVal
        this.chart.setOption(this.option);
      },
      deep: true // 开启深度监听  
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chartDom = this.$refs.chartDom;
      this.chart= echarts.init(chartDom);
      this.chart.setOption(this.option);
      // 响应式调整图表大小  
      window.addEventListener('resize', () => {
        this.chart.resize();
      });
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose(); // 销毁图表实例  
    }
  }
};

</script>