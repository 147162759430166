<template>
    <!-- <h1>参赛表</h1> -->
   <div style="width: 100%; height: 50px;  display: flex; ">
     <div class="club" style="margin-right: 20px;margin-left: 20px;">
       <label>赛事名:</label>
       <el-input v-model="inputname" style="width: 150px" placeholder="Please input" />
     </div>
     <el-button type="primary" @click="condition(1)">查询</el-button>
   </div>
  
   <div class="main">
     <el-scrollbar height="700px">
       <el-table :data="tableData" style="width: 100%" height="700">
         <el-table-column prop="fieldid" label="参加编号" width="100" header-align="center"  align="center"/>
         <el-table-column prop="clubname" label="俱乐部名称" width="100"  header-align="center" align="center"/>
         <el-table-column prop="principal" label="俱乐部负责人" width="150"  header-align="center" align="center" />
         <el-table-column prop="money" label="押金" width="100" />
         <el-table-column prop="competitionname" label="赛事名称" width="100"  header-align="center" align="center"/>
         <el-table-column prop="phone" label="负责人电话 " width="100" header-align="center" align="center"/>
         <el-table-column>
           <template #header>
             <div style="display: flex;">
               <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                 <Search />
               </el-icon>
               <input type="text"
                 style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;" v-model="seaid">
             </div>
           </template>
           <template #default="scope">
             <el-button v-if="showEventInfo1" plain size="small" @click="handleEdit(scope.$index, scope.row)"
               v-on:click="dialogFormVisible = true">
               编辑
             </el-button>
             <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
               plain v-on:click="centerDialogVisible = true">
               删除
             </el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-scrollbar>
     <div class="tanchuang">
       <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
         <el-form :model="form" class="for">
           <el-form-item>
           </el-form-item>
           <el-form-item label="俱乐部名称">
             <el-input v-model="clubname" autocomplete="off" style="width: 200px; height: 40px;" />
           </el-form-item>
           <el-form-item label="俱乐部负责人">
             <el-input v-model="name" autocomplete="off" style="width: 200px; height: 40px;" />
           </el-form-item>
           <el-form-item label="负责人电话">
             <el-input v-model="userphone" autocomplete="off" style="width: 200px; height: 40px;" />
           </el-form-item>
         </el-form>
         <template #footer>
           <div class="dialog-footer">
             <el-button @click="dialogFormVisible = false"> 取消</el-button>
             <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
               保存
             </el-button>
           </div>
         </template>
       </el-dialog>
  
       <div class="deleteDialog">
         <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
           <span>
             是否确定删除此条数据
           </span>
           <template #footer>
             <div class="dialog-footer">
               <el-button @click="centerDialogVisible = false">否</el-button>
               <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                 是
               </el-button>
             </div>
           </template>
         </el-dialog>
       </div>
     </div>
     <div>
       <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
         :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
         :total="total">
       </el-pagination>
     </div>
  
   </div>
  </template>
  <script setup>
  import {
   Search, Plus, Refresh
  } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  const dialogFormVisible = ref(false)
  const centerDialogVisible = ref(false)
  </script>
  
  <script>
  import { reactive, ref } from 'vue'
  import axios from 'axios'
  export default {
  
   data() {
     return {
       tableData: [],
       currentPage: 1,  //当前页
       pageSize: 10, //页面数据条数
       total: '100',
       clubname:'',//俱乐部名称
       name:'',//负责人名称
       userphone:'',//负责人电话
       inputname:'',//赛事名
       showEventInfo1:'',
       fieldid:'',//参赛id
       money:'',
     }
   },
  
   methods: {
  
     //换条数
     handleSizeChange(val) {
       this.pageSize = val;
       this.info()
     },
     //换页数
     handleCurrentChange(val) {
       this.currentPage = val;
       this.info()
     },
     //获取球员信息
     info() {
       axios.get(`/api/field/Attendlist?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
         console.log(rel)
         this.tableData = rel.data.data.records
         this.currentPage = rel.data.data.current
         this.pageSize = rel.data.data.size
         this.total = rel.data.data.total
       })
     },
     //信息回显
     handleEdit(number, User) {
       console.log(number, User)
       this.clubname=User.clubname,
       this.name=User.principal,
       this.userphone=User.phone
       this.fieldid=User.fieldid
       this.money=User.money
     },
     //编辑
     editplay() {
       axios.put('/api/field/upatten', {
        fieldid:this.fieldid,
        clubname:this.clubname,
        principal:this.name,
        phone: this.userphone,
        money:this.money
       }).then((rel) => {
         if(rel.data.code==200){
            this.info()
            ElMessage({
           message: '编辑成功',
           type: 'success',
         })
         }else{
            ElMessage({
           message: '编辑失败',
           type: 'error',
         })
         }
         
       })
     },
     // 删除方法
     deleteat() {
       const id = this.fieldid
       axios.delete(`/api/field/deAttend?id=${id}`).then((rel) => {
         console.log(rel);
         ElMessage({
           message: '删除成功',
           type: 'success',
         })
         this.info()
       })
     },
     //上传文件提交选定表
     submitUpload() {
       this.$refs.uploadRef.submit();
     },
     //删除按钮行数选定
     handleDelete(index, User) {
       console.log(index, User)
       this.deleteId = User.userid
  
     },
    
     //身份认证
     userpost() {
       const posts = localStorage.getItem("posts")
       console.log("身份验证",posts)
       if (posts == "后端管理员" || posts == "教练负责人") {
         console.log("身份验证成功")
         this.showEventInfo1 = true;
       } else {
         console.log("身份验证结束")
         this.showEventInfo1 = false;
       }
     },
     //筛选
     condition(s) {
       axios.post('/api/field/condition', {
         name: this.inputname,
         ctype:s,
       }).then((rel)=>{
         console.log(rel)
         this.tableData = rel.data.data  
       })
     },
  
   },
  
   created() {
     this.info();
     this.userpost()
   },
  
  
  
  }
  </script>
  
  <style scoped>
  .head {
   border: 1px solid #000;
   width: 100%;
   height: 50px;
  }
  
  .avatar-uploader .el-upload {
   border: 1px dashed #d9d9d9;
   border-radius: 6px;
   cursor: pointer;
   position: relative;
   overflow: hidden;
  }
  
  .avatar-uploader .el-upload:hover {
   border-color: #409EFF;
  }
  
  .avatar-uploader-icon {
   font-size: 28px;
   color: #8c939d;
   width: 178px;
   height: 178px;
   line-height: 178px;
   text-align: center;
  }
  
  .avatar {
   width: 178px;
   height: 178px;
   display: block;
  }
  
  .for {
   display: flex;
   flex-direction: column;
   align-items: center;
   /* 垂直居中 */
   /* 注意：这种方式会影响所有表单项，包括它们的标签 */
  }
  </style>