<template>
  <div class="info" style="display: flex;">

    <div class="inputmode" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">

      <el-card style="width: 400px; height: 500px;margin: auto;">
        <template #header>
          <div class="card-header">
            <span>
              <div class="touxiang">

                <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" size="large" />

              </div>
            </span>
          </div>
        </template>
        <div>
          <div>
            <span>账号：<el-input v-model="inputA" style="width: 240px; margin-bottom: 10px; " disabled="true"
                placeholder="Please input" /></span>
          </div>

          <div>
            <span>编号：<el-input v-model="inputF" style="width: 240px; margin-bottom: 10px; " disabled="true"
                placeholder="Please input" /></span>
          </div>
          <div>
            <span>姓名：<el-input v-model="inputB" style="width: 240px; margin-bottom: 10px; " disabled="true"
                placeholder="Please input" /></span>
          </div>
          <div>

            <span>职位：<el-input v-model="inputC" style="width: 240px; margin-bottom: 10px; " disabled="true"
                placeholder="Please input" /></span>
          </div>
          <div>

            <span>状态：<el-input v-model="inputD" style="width: 240px; margin-bottom: 10px; " disabled="true"
                placeholder="Please input" /></span>
          </div>
          <div>

            <span>电话：<el-input v-model="inputE" style="width: 240px; margin-bottom: 10px; " disabled="true"
                placeholder="Please input" /></span>
          </div>
        </div>



      </el-card>

    </div>

    <div>
      <el-tabs v-loading="loading" element-loading-text="正在修改" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" type="border-card" v-model="activeName" class="demo-tabs"
        style="margin-left: 50px; width: 1000px; height: 500px; ">
        <el-tab-pane label="基本信息" name="first" style="font-size: small; margin-right: 600px; ">
          <div>
            <span>账号：<el-input v-model="inputA" style="width: 240px; margin-bottom: 10px; "
                placeholder="Please input" /></span>
          </div>
          <div>
            <span>姓名：<el-input v-model="inputB" style="width: 240px; margin-bottom: 10px; "
                placeholder="Please input" /></span>
          </div>
          <div>
            <span>电话：<el-input v-model="inputE" style="width: 240px; margin-bottom: 10px; "
                placeholder="Please input" /></span>
          </div>
          <div class="butt">
            <button @click="baochun()"
              style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin-right: 40px">保存</button>
            <button @click="dd()"
              style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin-left: 40px">取消</button>

          </div>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="second" style="font-size: small; margin-right: 600px;">
          <div>
            <span>旧密码：<el-input show-password v-model="inputG" style="width: 240px; margin-bottom: 10px; "
                placeholder="Please input" /></span>
          </div>
          <div>
            <span style="width: 300px;">新密码：<el-input show-password name="pA" v-model="newpwdA"
                style="width: 240px; margin-bottom: 10px; " placeholder="Please input" /></span>
          </div>
          <label>{{ pwtext }}</label>
          <div>
            <span>确认密码：<el-input name="pB" v-model="newpwdB" show-password style="width: 240px; margin-bottom: 10px; "
                placeholder="Please input" /> </span>
          </div>
          <label>{{ pwtext }}</label>
          <div class="butt">
            <button @click="pWUp()"
              style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin-right: 40px">保存</button>
            <button
              style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin-left: 40px">取消</button>

          </div>
        </el-tab-pane>
      </el-tabs>
    </div>


  </div>
</template>
<script setup>
import { ElMessage } from 'element-plus'
</script>
<script>
import axios from 'axios'
export default {

  data() {
    return {
      inputA: '',
      inputB: '',
      inputC: '',
      inputD: '',
      inputE: '',
      inputF: '',
      activeName: 'first',
      inputG: '',//旧密码
      newpwdA: '',
      newpwdB: '',
      pwtext: '',
      loading: false,
    }
  },

  methods: {
    //登录信息请求
    loginInfoApi() {
      axios.get('/api/userinfo',).then((rel) => {
        console.log(rel.data);
        this.inputA = rel.data.data.username
        this.inputB = rel.data.data.name
        this.inputC = rel.data.data.post
        this.inputD = rel.data.data.state
        this.inputE = rel.data.data.userphone
        this.inputF = rel.data.data.userid

      })
    },
    dd() {

    },
    // handleClick(target, action) {
    //             console.log(target.name, action)
    //         },
    baochun() {
      this.loading = true
      axios.post('/api/upuser', {
        username: this.inputA,
        name: this.inputB,
        userphone: this.inputE,
      }).then((rel) => {
        this.loading = false
        if (rel.data.code == 200) {
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
        } else {
          ElMessage.error('修改失败')
        }
      })
      // 修改信息

    },
    pWUp() {
      if (this.newpwdA == this.newpwdB) {
        this.pwtext='';
        this.loading = true
        axios.post('/api/upuserpassword', {
          password: this.newpwdA,
          username:this.inputG,
        }).then((rel) => {
          console.log(rel)
          if (rel.data.code == 200) {
            this.loading = false
            ElMessage({
              message: '修改成功',
              type: 'success',
            })
          }else{
            this.loading = false
            ElMessage({
              message: rel.data.message,
              type: 'error',
            })
          }
        })
      } else {
        this.pwtext = "密码不一致或者为空";
      }
      //修改密码
    }

  },

  created() {
    this.loginInfoApi()
  },
}
</script>

<style>
.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>