<template>
    <div class="input-container">
        <div class="input-wrapper">姓名：<el-input v-model="name" placeholder="姓名" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">账号：<el-input v-model="username" placeholder="账号" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">密码：<el-input v-model="password" placeholder="密码" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">状态：<el-input v-model="state" placeholder="状态" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">电话：<el-input v-model="phone" placeholder="电话" style="width: 200px;"></el-input>
        </div>
        <div class="input-wrapper">职位：<el-input v-model="post" placeholder="职位" style="width: 200px;"></el-input>
        </div>
    </div>
    <div class="butt">
        <button @click="useradd()"
            style="border-radius: 10px; width: 100px; height: 30px; background: #259b24; border: none; color: #fff; margin: 40px">添加用户</button>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios';
export default {
    data() {
        return {
            imageUrl: '',
            sqlimg: '',
            name: '',
            username: '',
            password: '',
            state: '',
            phone: '',
            post: '',
        }
    },

    methods: {

        useradd() {
            axios.post('/api/user/useradd', {
                name: this.name,//姓名
                state: this.state,//状态
                username: this.username,//账号
                userphone: this.phone,//电话号码
                password:this.password,//密码
                post: this.post,//职位
            }).then((rel) => {
                if (rel.data.code == 200) {
                    ElMessage({
                        message: '成功',
                        type: 'success',
                    })
                   
                } else {
                    ElMessage({
                        message: '失败',
                        type: 'error',
                        plain: true,
                    })
                }

            })
        }

    },

    mounted() {

    },

}
</script>

<style></style>