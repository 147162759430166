<template>
  <div style="width: 100%; height: 50px;  display: flex; ">
    <div class="hi">
      <label>身高:</label>
      <el-input v-model="hmin" style="width: 50px" placeholder="min" />--<el-input v-model="hmax" style="width: 50px"
        placeholder="max" />
    </div>
    <div class="wt" style="margin-right: 20px;margin-left: 20px;">
      <label>体重:</label>
      <el-input v-model="wmin" style="width: 50px" placeholder="min" />--<el-input v-model="wmax" style="width: 50px"
        placeholder="max" />
    </div>
    <div class="wt" style="margin-right: 20px;margin-left: 20px;">
      <label>年龄:</label>
      <el-input v-model="amin" style="width: 50px" placeholder="min" />--<el-input v-model="amax" style="width: 50px"
        placeholder="max" />
    </div>
    <div class="hi" style="margin-right: 20px;margin-left: 20px; display: flex;">
      <label style="width: 100px; margin-top: 7px;">状态:</label>
      <el-select v-model="st" placeholder="请选择">
        <el-option :label="启用" :value='1'>
        </el-option>
        <el-option :label="禁用" :value='0'></el-option>
      </el-select>
    </div>
    <div class="club" style="margin-right: 20px;margin-left: 20px;">
      <label>俱乐部:</label>
      <el-input v-model="inputclub" style="width: 150px" placeholder="Please input" />
    </div>
    <div class="club" style="margin-right: 20px;margin-left: 20px;">
      <label>司职:</label>
      <el-input v-model="inputpost" style="width: 100px" placeholder="Please input" />
    </div>
    <div class="phone" style="margin-right: 20px;margin-left: 20px;">
      <label>电话:</label>
      <el-input v-model="inputphone" style="width: 120px" placeholder="Please input" />
    </div>
    <el-button type="primary" @click="condition(2)">模糊查询</el-button>
    <el-button type="primary" @click="condition(1)">精确查询</el-button>
    <el-button type="primary" @click="ToAdd()">添加人员</el-button>
  </div>
  <!-- <h1>青训人员</h1> -->
  <div class="main">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%" height="700">
        <el-table-column prop="userid" label="ID" width="140" />
        <el-table-column prop="name" label="姓名" width="190" />
        <el-table-column prop="state" label="状态" width="190" >
          <template v-slot="scope">
            {{ scope.row.state === 0 ? '禁用' : '启用' }}
          </template>
        </el-table-column>
        <el-table-column prop="userphone" label="联系方式" width="150" />
        <el-table-column prop="post" label="司职" width="190" />
        <el-table-column prop="img" label="照片" width="190">
          <template #default="scope">
            <el-image :src="getImage(scope.row.img)" style="width:100px ;"
              preview-src-list="=[ `/aip/common/download?name=${scope.row.image}` ]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="clubname" label="俱乐部" width="180" />
        <el-table-column prop="updata" label="更新时间" width="180" />
        <el-table-column>
          <template #header>
            <div style="display: flex;">
              <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                <Search />
              </el-icon>
              <input type="text"
                style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;" v-model="seaid">
            </div>
          </template>
          <template #default="scope">
            <el-button v-if="showEventInfo1" plain size="small" @click="handleEdit(scope.$index, scope.row)"
              v-on:click="dialogFormVisible = true">
              编辑
            </el-button>
            <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
              plain v-on:click="centerDialogVisible = true">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="tanchuang">
      <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
        <el-form :model="form" class="for">
          <el-form-item>
            <div>
              <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
                :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                :on-change="handleFileChange" :auto-upload="false">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <button @click="submitUpload()" type="button">上传图片</button>
            </div>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="name" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="状态">
            <el-input v-model="state" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="电话">
            <!-- <el-select v-model="form.region" placeholder="填写职位">
              <el-option label="Zone No.1" value="shanghai" />
              <el-option label="Zone No.2" value="beijing" />
            </el-select> -->
            <el-input v-model="userphone" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="司职">
            <el-input v-model="post" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="身高">
            <el-input v-model="height" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="体重">
            <el-input v-model="weight" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="年龄">
            <el-input v-model="age" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="俱乐部">
            <el-input v-model="clubname" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
          </el-form-item> -->
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false"> 取消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>

      <div class="deleteDialog">
        <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
          <span>
            是否确定删除此条数据
          </span>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="centerDialogVisible = false">否</el-button>
              <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                是
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
    <div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

  </div>
</template>
<script setup>
import {
  Search, Plus, Refresh
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
const dialogFormVisible = ref(false)
const centerDialogVisible = ref(false)
</script>

<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
export default {

  data() {
    return {
      tableData: [],
      currentPage: 1,  //当前页
      pageSize: 10, //页面数据条数
      total: '100',
      name: '',
      userid: '',
      state: '',
      userphone: '',
      post: '',
      height: '',
      weight: '',
      age: '',
      sqlimg: '',
      imageUrl: '',//编辑照片显示路径
      clubname: '',
      updata: '',
      fileList: [],
      deleteId: '',
      showEventInfo1:true,
      hmin: '',
      hmax: '',
      wmin: '',
      wmax: '',
      amin: '',
      amax: '',
      st: '',
      inputclub: '',
      inputpost: '',
      inputphone: '',
      seaid:'',



    }
  },

  methods: {

    //换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.info()
    },
    //换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.info()
    },
    //获取球员信息
    info() {
      axios.get(`/api/athletes/athleteslist?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data.records
        this.currentPage = rel.data.data.current
        this.pageSize = rel.data.data.size
        this.total = rel.data.data.total
      })
    },
    //上传图片完成回调
    handleAvatarSuccess(res, file) {
      this.sqlimg = res.data;
      console.log(this.sqlimg)
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl)
    },
    //上传图片过滤
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //球员信息回显
    handleEdit(number, User) {
      console.log(number, User)
      this.name = User.name
      this.userid = User.userid
      this.state = User.state
      this.userphone = User.userphone
      this.post = User.post
      this.height = User.height
      this.weight = User.weight
      this.age = User.age
      this.clubname = User.clubname
      this.updata = User.updata
      this.imageUrl = `/api/common/download?name=${User.img}`
    },
    //编辑
    editplay() {
      axios.put('/api/athletes/upathletes', {
        name: this.name,
        userid: this.userid,
        state: this.state,
        userphone: this.userphone,
        post: this.post,
        height: this.height,
        weight: this.weight,
        age: this.age,
        clubname: this.clubname,
        updata: this.updata,
        img: this.sqlimg,
      }).then((rel) => {
        alert("修改成功")
        this.info()
      })
    },
    //查询方法
    Searchqinxun() {
      const id =this.seaid
      axios.get(`/api/athletes/Search?id=${id}`).then((rel) => {
        console.log(rel);
        this.tableData = rel.data.data  
      })
    },
    // 删除方法
    deleteat() {
      const id = this.deleteId
      axios.delete(`/api/athletes/deathletes?id=${id}`).then((rel) => {
        console.log(rel);
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        this.info()
      })
    },
    //上传文件提交选定表
    submitUpload() {
      this.$refs.uploadRef.submit();
    },
    //删除按钮行数选定
    handleDelete(index, User) {
      console.log(index, User)
      this.deleteId = User.userid

    },

    // upimg(){
    //   axios.post('/api/common/upload',{

    //   }).then((rel)=>{
    //     console.log(rel)
    //   })
    // },
    //编辑上传图片变换
    handleFileChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }

      // 当文件状态发生变化时触发，此处用于更新预览图片  
    },
    //身份认证
    userpost() {
      const posts = localStorage.getItem("posts")
      console.log("身份验证",posts)
      if (posts == "后端管理员" || posts == "青训负责人") {
        console.log("身份验证成功")
        this.showEventInfo1 = true;
      } else {
        console.log("身份验证结束")
        this.showEventInfo1 = false;
      }
    },
    condition(s) {
      axios.post('/api/athletes/condition', {
        hmin: this.hmin,
        hmax: this.hmax,
        wmin: this.wmin,
        wmax: this.wmax,
        amin: this.amin,
        amax: this.amax,
        st: this.st,
        inputclub: this.inputclub,
        inputpost: this.inputpost,
        inputphone: this.inputphone,
        ctype:s,
      }).then((rel)=>{
        console.log(rel)
        this.tableData = rel.data.data  
      })
    },
    getImage(image) {//图片遍历
      return `/api/common/download?name=${image}`
    },
    
    ToAdd(){
    this.$router.push({ name: 'add' });
   }
  },

  created() {
    this.info();
    this.userpost()
  },



}
</script>

<style>
.head {
  border: 1px solid #000;
  width: 100%;
  height: 50px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.for {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 垂直居中 */
  /* 注意：这种方式会影响所有表单项，包括它们的标签 */
}
</style>